import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
// import uploadImg from '../../../../images/upload-img.png';
import ManufacturerService from "../../../../services/ManufacturerService";
// import BaseService from "../../../../services/BaseService";
// import Loader from "../../../common/Loader";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
// import Select from "react-select";
// import BrandsService from "../../../../services/BrandsService";

const AddManufacturerModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        title_en: '',
        title_ar: '',
        code: '',
        // start_from: '',
        // quantity: '',
        // brand: '',
        // image: ''
    })
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const manufacturerService = new ManufacturerService()

    useEffect(() => {
        if(Object.keys(item)?.length === 0){
            setIsAdd(true)
        } else {
            setIsAdd(false)
            setFormData({
                id: item?.id,
                title_en: item?.title_en,
                title_ar: item?.title_ar,
                code: item?.code,
            })
        }
    },[item])

    // const fileHandler = (e) => {
    //     if(e.target.files?.length === 0){
    //         return
    //     }
    //     setLoading(true)
    //     let files = e.target.files
    //     const filesData = Object.values(files)
 
    //     if (filesData.length) {
    //         new BaseService().postUpload(filesData[0]).then(res=>{
    //             if(res.data.status){
    //                 setFormData({...formData, image: res.data.url})
    //             }
    //             setLoading(false)
    //         })
    //     }
    // }

    const submit = () =>{
        // if(!formData?.image){
        //     return
        // }
        let data ={
            title_en: formData?.title_en,
            title_ar: formData?.title_ar,
            code: formData?.code
        }
        setLoading(true)
        if(isAdd){
            manufacturerService.create(data)?.then(res=>{
                if(res && res?.status === 201){
                    toast.success('Manufacturer Added Successfully')
                    setAddModal()
                    setShouldUpdate(prev=> !prev)
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        } else {
            manufacturerService.update(formData?.id, data)?.then(res=>{
                if(res && res?.status === 200){
                    toast.success('Manufacturer Updated Successfully')
                    setAddModal()
                    setShouldUpdate(prev=> !prev)
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    }

    return(
        <Modal className={lang === 'en' ? "en fade" : "ar fade"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang]?.add : Translate[lang]?.edit} {Translate[lang]?.manufacturer}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                
                    <Row>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.english_title}
                                type='text'
                                placeholder={Translate[lang]?.english}
                                bsSize="lg"
                                name='title_en'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.title_en}
                                onChange={(e) => setFormData({...formData, title_en: e.target.value})}
                            />
                        </Col>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.arabic_title}
                                type='text'
                                placeholder={Translate[lang]?.arabic}
                                bsSize="lg"
                                name='title_ar'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.title_ar}
                                onChange={(e) => setFormData({...formData, title_ar: e.target.value})}
                            />
                        </Col>
                        {/* <Col md={6}>
                            <AvField
                                label={Translate[lang]?.start_from}
                                type='number'
                                placeholder={Translate[lang]?.price}
                                bsSize="lg"
                                min="0.0000000000001"
                                name='start_from'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.start_from}
                                onChange={(e) => setFormData({...formData, start_from: e.target.value})}
                            />
                        </Col> */}
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.code}
                                type='text'
                                placeholder={Translate[lang]?.code}
                                bsSize="lg"
                                name='code'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.code}
                                onChange={(e) => setFormData({...formData, code: e.target.value})}
                            />
                        </Col>
                        {/* <Col md={6}>
                            <AvField
                                label={Translate[lang]?.quantity}
                                type='number'
                                placeholder={Translate[lang]?.quantity}
                                bsSize="lg"
                                name='quantity'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.quantity}
                                onChange={(e) => setFormData({...formData, quantity: e.target.value})}
                            />
                        </Col>
                        <Col md={6} sm={6} className="mb-3">
                            <label className="text-label">{Translate[lang]?.brand}</label>
                            <Select
                            value={formData.brand}
                            name="brand"
                            placeholder={Translate[lang]?.select}
                            options={brandsOptions}
                            onChange={(e) => setFormData({...formData, brand: e})}
                            />
                        </Col>
                        <Col md={12}>
                                <div className='form-group w-100'>
                                    <label className="m-0">{Translate[lang]?.image}</label>
                                    <div className="image-placeholder" style={{maxWidth: 'fit-content'}}>	
                                        <div className="avatar-edit h-100">
                                            <input 
                                                type="file" 
                                                className="d-block w-100 h-100 cursor-pointer" 
                                                style={{opacity: '0'}}
                                                onChange={(e) => fileHandler(e)}
                                            /> 					
                                        </div>
                                        <div className="avatar-preview2 m-auto">
                                            <div>
                                            {!!formData?.image && <img src={formData.image} alt='icon'
                                                    className='w-100 h-100' style={{borderRadius: '30px'}} 
                                                />}
                                            {(!formData?.image && !loading) && 
                                                <img
                                                    src={uploadImg} alt='icon'
                                                    style={{
                                                        width: '80px',
                                                        height: '80px',
                                                    }}
                                                />}
                                            {(!formData?.image && loading) && <Loader />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </Col> */}
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddManufacturerModal;