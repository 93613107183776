import { Link } from "react-router-dom";

const CardItem = ({item, index}) =>{
    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>{item?.user?.username}</td>
            <td>{item?.user?.company_name}</td>
            <td>{item?.user?.email}</td>
            <td>{item?.user?.phone}</td>
            <td>{item?.quotation?.reference_id}</td>
            <td style={{textWrap: 'nowrap'}}>{item?.quotation?.delivery}</td>
            <td style={{textWrap: 'nowrap'}}>{item?.quotation?.validity}</td>
            <td style={{textWrap: 'nowrap'}}>{item?.quotation?.payment}</td>
            <td>{item?.total.toFixed(3)}</td>

            <td>
                <Link to='/order-quotations/details' state={item}>
                    <i className="la la-eye" style={{fontSize: '27px'}}></i>
                </Link>
            </td>
        </tr>
    )
}
export default CardItem;