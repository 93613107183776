import dashboard from "../../images/icons/dashboard.png"
import admins from "../../images/icons/admin.png"
import brand from "../../images/icons/brand-image.png"
import categories from "../../images/icons/categories.png"
import ordersGuest from "../../images/icons/cargo.png"
import orders from "../../images/icons/order-now.png"
import products from "../../images/icons/product (1).png"
import rules from "../../images/icons/shield.png"
import suppliers from "../../images/icons/supplier.png"
import variant from "../../images/icons/variant.png"
import chat from "../../images/icons/chat.png"
import location from "../../images/icons/map.png"
import promoCode from "../../images/icons/promo-code.png"
import banner from "../../images/icons/banners.png"
import quotations from "../../images/icons/quotation.png"
import wholesale from "../../images/icons/wholesaler.png"
import setting from "../../images/icons/settings.png"
import users from "../../images/icons/users.png"

export const MenuList = [
  // Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-title",
    iconStyle: <img src={dashboard} alt="dashboard" />,
    // iconStyle: <i className="la la-pie-chart"></i>,
    to: "dashboard",
    text: "dashboard",
  },
  // Admins
  {
    title: "Admins Management",
    classsChange: "mm-title",
    iconStyle: <img src={admins} alt="admins" />,
    // iconStyle: <i className="la la-user-shield"></i>,
    to: "admins",
    text: "admins",
  },
  // Rules
  {
    title: "Rules",
    classsChange: "mm-title",
    iconStyle: <img src={rules} alt="rules" />,
    // iconStyle: <i className="la la-shield"></i>,
    to: "rules",
    text: "rules",
  },
  // Users
  {
    title: "Users",
    classsChange: "mm-title",
    iconStyle: <img src={users} alt="users" />,
    to: "users",
    text: "users",
  },
  // Categories
  {
    title:'Categories',
    classsChange: "mm-title",
    iconStyle: <img src={categories} alt="categories" />,
    // iconStyle: <i className="la la-cubes"></i>,
    text:'categories',
    to: 'categories',
  },
  // Brands
  {
    title:'Brands',
    classsChange: "mm-title",
    iconStyle: <img src={brand} alt="brand" />,
    // iconStyle: <i className="la la-slack"></i>,
    text:'brands',
    to: 'brands',
  },
  // Products
  {
    title:'Products',
    classsChange: "mm-title",
    iconStyle: <img src={products} alt="products" />,
    // iconStyle: <i className="la la-cube"></i>,
    text:'products',
    to: 'products',
  },
  // Variant
  {
    title:'Variant',
    classsChange: "mm-title",
    iconStyle: <img src={variant} alt="variant" />,
    // iconStyle: <i className="la la-simplybuilt"></i>,
    text:'variant',
    to: 'variant',
  },
  // Dynamic Variant
  {
    title:'Dynamic Variant',
    classsChange: "mm-title",
    iconStyle: <img src={variant} alt="variant" />,
    // iconStyle: <i className="la la-simplybuilt"></i>,
    text:'dynamic_variant',
    to: 'dynamic-variant',
  },
  // Orders
  {
    title: "Orders",
    classsChange: "mm-collapse",
    iconStyle: <img src={orders} alt="orders" />,
    // iconStyle: <i className="la la-truck"></i>,
    to: "orders",
    text: "orders",
  },
  // Orders Guest
  {
    title: "Orders Guest",
    classsChange: "mm-collapse",
    iconStyle: <img src={ordersGuest} alt="orders" />,
    // iconStyle: <i className="la la-truck"></i>,
    to: "orders-guest",
    text: "orders_guest",
  },
  // Suppliers
  {
    title: "Suppliers",
    classsChange: "mm-collapse",
    iconStyle: <img src={suppliers} alt="suppliers" />,
    // iconStyle: <i className="la la-dropbox"></i>,
    to: "suppliers",
    text: "suppliers",
  },
  // Manufacturer
  {
    title: "Manufacturer",
    classsChange: "mm-collapse",
    iconStyle: <img src={wholesale} alt="manufacturer" />,
    // iconStyle: <i className="la la-dolly"></i>,
    to: "manufacturer",
    text: "manufacturer",
  },
  // Quotations
  {
    title: "Quotations",
    classsChange: "mm-collapse",
    iconStyle: <img src={quotations} alt="quotations" />,
    // iconStyle: <i className="la la-dolly"></i>,
    to: "quotations",
    text: "quotations",
  },
  // Order Quotations
  {
    title: "Order Quotations",
    classsChange: "mm-collapse",
    iconStyle: <img src={quotations} alt="quotations" />,
    // iconStyle: <i className="la la-dolly"></i>,
    to: "order-quotations",
    text: "order_quotations",
  },
  // Location
  {
    title: "Location",
    classsChange: "mm-collapse",
    iconStyle: <img src={location} alt="location" />,
    // iconStyle: <i className="la la-map-marked-alt"></i>,
    to: "location",
    text: "location",
  },
  // Promo Codes
  {
    title: "Promo Codes",
    classsChange: "mm-collapse",
    iconStyle: <img src={promoCode} alt="promo" />,
    // iconStyle: <i className="la la-dollar"></i>,
    to: "promo-codes",
    text: "promo_codes",
  },
  // Banners
  {
    title: "Banners",
    classsChange: "mm-collapse",
    iconStyle: <img src={banner} alt="banner" />,
    // iconStyle: <i className="la la-tv" />,
    to: "banners",
    text: "banners",
  },
  // Contact Us
  {
    title: "Contact Us",
    classsChange: "mm-collapse",
    iconStyle: <img src={chat} alt="chat" />,
    // iconStyle: <i className="la la-bell"></i>,
    text: "contact_us",
    to: "contact-us",
  },
  // Home
  // {
  //     title:'Home',
  //     text:'home',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="la la-slack" />,
  //     content : [
  //         {
  //             title:'Page',
  //             text:'page',
  //             to: 'home/page',
  //             rule: 'home',
  //         },
  //         {
  //             title:'Products',
  //             text:'products',
  //             to: 'home/products',
  //             rule: 'home',
  //         },
  //         {
  //             title: "Social Media",
  //             text: "social_media",
  //             to: "home/social",
  //             rule: "home",
  //           },
  //         {
  //           title: "Contact Us",
  //           text: "contact_us",
  //           to: "home/contact-us",
  //           rule: "home",
  //         },
  //         {
  //           title: "About Us",
  //           text: "about_us",
  //           to: "home/about-us",
  //           rule: "home",
  //         },
  //     ]
  // },
  // Setting
  {
      title:'Setting',
      text:'setting',
      classsChange: 'mm-collapse',
      iconStyle: <img src={setting} alt="promo" />,
      // iconStyle: <i className="la la-cog" />,
      content : [
          {
            title: "Delivery",
            text: "delivery",
            to: "delivery",
            rule: "delivery",
          },
          {
            title: "Social Media",
            text: "social_media",
            to: "social",
            rule: "social",
          },
          {
            title: "Payment",
            text: "payment",
            to: "payment",
            rule: "payment",
          },
          {
            title: "Clients",
            text: "clients",
            to: "clients",
            rule: "static_pages",
          },
          {
            title: "About Us",
            text: "about_us",
            to: "about-us",
            rule: "static_pages",
          },
          {
            title: "Privacy",
            text: "privacy",
            to: "privacy",
            rule: "static_pages",
          },
          {
            title: "Values",
            text: "values",
            to: "values",
            rule: "static_pages",
          },
          {
            title: "Company",
            text: "company",
            to: "company",
            rule: "static_pages",
          },
          {
            title: "Terms and Conditions",
            text: "terms_and_conditions",
            to: "terms",
            rule: "static_pages",
          },
      ]
  },
  // Social Media
  // {
  //   title: "Social Media",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-wrench"></i>,
  //   to: "home/social",
  //   text: "social_media",
  // },

  // <i className='la la-cubes'></i>,
  // <i className='la la-cube'></i>,
  // <i className="la la-slack" />,
  // <i className="flaticon-381-notepad" />,
  // <i className="la la-qrcode"></i>
  // <i className='la la-truck'></i>
  // <i className='la la-pie-chart'></i>
  // <i className='la la-dollar'></i>
  // <i className="la la-wrench" />
  // <i className="la la-simplybuilt" />
  // <i className='la la-bell'></i>
];
