import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const CardItem = ({item, index}) =>{
    const [status, setStatus] = useState(null)

    useEffect(()=>{
        setStatus(item.isBlocked)
    },[item])
    
    return(
        <tr key={index} className='text-center'>
                    <td>
                      <strong>{item?.id}</strong>
                    </td>
                    <td>
                      <p className="mb-0"
                        style={{fontWeight: !!item.username && '800',opacity:'.75', textTransform: 'capitalize'}}>
                        {item?.type === 'user' ? item?.username : item?.company_name|| '-'} 
                      </p>
                    </td>
                    <td>
                      {item?.email || '-'}
                    </td>
                    <td>
                      {item?.phone || '-'}
                    </td>
                    {item?.type === 'whole_sale' && <td>
                      <a href={item?.civil_id} target="_blank" rel="noreferrer">
                        <i style={{fontSize: '2.5rem'}} className="la la-file-pdf"></i>
                      </a>
                    </td>}
                    {item?.type === 'whole_sale' && <td>
                      <a href={item?.authorized_signatory} target="_blank" rel="noreferrer">
                        <i style={{fontSize: '2.5rem'}} className="la la-file-pdf"></i>
                      </a>
                    </td>}
                    {item?.type === 'whole_sale' && <td>
                      <a href={item?.company_license} target="_blank" rel="noreferrer">
                        <i style={{fontSize: '2.5rem'}} className="la la-file-pdf"></i>
                      </a>
                    </td>}
                    <td>
                      <Form.Check
                        type="switch"
                        id={`custom-switch${index}`}
                        checked={!status}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`isDeleted${index}`}
                        checked={item.isDeleted}
                      />
                    </td>
                  </tr>
    )
}
export default CardItem;