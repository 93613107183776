import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Translate } from "../../Enums/Tranlate";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import QuotationService from "../../../services/QuotationService";
import { toast } from "react-toastify";

const Quotation = () => {
    const [data, setData] = useState({})
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const location = useLocation()
    const quotationService = new QuotationService()

    useEffect(()=> {
        let info = location?.state
        setData(info)
        if((!!info?.delivery && !!info?.validity && !!info.payment) || info?.esen_status === "provided"){
            setEdit(false)
        } else {
            setEdit(true)
        }
    }, [location])

    const submit = () => {
        let params = {
            validity: data?.validity,
            delivery: data?.delivery,
            payment: data?.payment,
            quotation_id: data?.id,
            sub_quotations: data?.sub_quotations?.map(res=> {
                return {
                    price: res?.price,
                    id: res?.id,
                }
            })
        }
        setLoading(true)
        quotationService.sendRespone(params).then(res=>{
            if(res?.status){
                toast.success('Quotation Send Successfully.')
                setEdit(false)
                setData({...data, esen_status: 'pending'})
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }

    return <Card>
        <Card.Body>
            <AvForm onValidSubmit={submit}>
                <Table responsive className="mb-5">
                    <thead>
                    <tr className='text-center'>
                        <th>
                        <strong>I.D</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.name}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.unite}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.quantity}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.selling_price_two}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.total}</strong>
                        </th>
                    </tr>
                    </thead>

                    <tbody className="table-body">
                        {data?.sub_quotations?.map((item, index) =>{
                            return <tr key={index} className='text-center'>
                                <td>
                                    <strong>{item.id}</strong>
                                </td>
                                <td>{lang === 'en' ? item?.product?.name_en : item?.product?.name_ar}</td>
                                <td>{item?.product?.unit}</td>
                                <td>{item?.amount}</td>
                                <td>
                                    <input 
                                        type='number'
                                        value={item?.price}
                                        disabled={!edit}
                                        style={{
                                            padding: '8px 12px',
                                            width: '5rem',
                                            borderRadius: '8px',
                                            border: '1px solid #dedede'
                                        }}
                                        onChange={e => {
                                            let update = data?.sub_quotations?.map((res, ind) =>{
                                                if(index === ind){
                                                    return {
                                                        ...res,
                                                        price: e.target.value
                                                    }
                                                } else {
                                                    return res
                                                }
                                            })
                                            setData({...data, sub_quotations: update})
                                        }}
                                    />
                                </td>
                                <td>
                                    {(item?.amount*item?.price).toFixed(3)}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
                <Row>
                    <Col md={4} className="mb-3">
                        <AvField
                        label={`${Translate[lang]?.validity}*`}
                        type="text"
                        placeholder={Translate[lang]?.validity}
                        bsSize="lg"
                        name="validity"
                        disabled={!edit}
                        validate={{
                            required: {
                            value: true,
                            errorMessage: Translate[lang].field_required,
                            },
                        }}
                        value={data?.validity}
                        onChange={(e) => setData({...data, validity: e.target.value})}
                        />
                    </Col>
                    <Col md={4} className="mb-3">
                        <AvField
                        label={`${Translate[lang]?.delivery}*`}
                        type="text"
                        placeholder={Translate[lang]?.delivery}
                        bsSize="lg"
                        name="delivery"
                        disabled={!edit}
                        validate={{
                            required: {
                            value: true,
                            errorMessage: Translate[lang].field_required,
                            },
                        }}
                        value={data?.delivery}
                        onChange={(e) => setData({...data, delivery: e.target.value})}
                        />
                    </Col>
                    <Col md={4} className="mb-3">
                        <AvField
                        label={`${Translate[lang]?.payment}*`}
                        type="text"
                        placeholder={Translate[lang]?.payment}
                        bsSize="lg"
                        name="payment"
                        disabled={!edit}
                        validate={{
                            required: {
                            value: true,
                            errorMessage: Translate[lang].field_required,
                            },
                        }}
                        value={data?.payment}
                        onChange={(e) => setData({...data, payment: e.target.value})}
                        />
                    </Col>
                </Row>
                {data?.esen_status === 'new' && <div className="d-flex justify-content-between">
                    <div>
                        {edit && <Button type='button' onClick={()=> setEdit(false)} variant="danger light">
                            {Translate[lang]?.cancel}
                        </Button>}
                    </div>
                    <div>
                        {!edit && <Button type='button' onClick={()=> setEdit(true)} variant="secondary light" className="mx-3">
                            {Translate[lang]?.edit}
                        </Button>}
                        {edit && <Button 
                            variant="primary" 
                            type='submit'
                            disabled={loading}
                        >{Translate[lang]?.send}</Button>}
                    </div>
                </div>}
              </AvForm>
        </Card.Body>
    </Card>
}
export default Quotation;