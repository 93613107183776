import { useState } from "react";
import { Badge, Button, Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import logo from "../../../images/logo.png"
import Pagination from "../../common/Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import MHProductsService from "../../../services/MHProductsService";

const MasterHNProducts = () =>{
    const [products, setProducts] =useState([])
    const [hasData, setHasData] =useState(0)
    const [search, setSearch] =useState(null)
    const [loading, setLoading] =useState(false)
    const [indexEdit, setIndexEdit] = useState(null)
    const [isDeleted, setIsDeleted] =useState(false)
    const [ shouldUpdate, setShouldUpdate] = useState(false)
    const navigate = useNavigate()
    const lang = useSelector(state=> state.auth?.lang)
    const Auth = useSelector(state=> state.auth?.auth)
    const productsService = new MHProductsService()
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    const print = () => {
      const printWindow = window.open("", "_blank");
      let productsCode = ``;
      for(let i = 0; i < products?.length; i++){
          productsCode += `<tr>
          <td>${products[i]?.id}</td>
          <td>${lang === 'en' ? products[i]?.name_en : products[i]?.name_ar}</td>
          <td>
            ${products[i]?.location_of_products?.map(res => res?.amount)?.reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            }, 0)}
          </td>
          <td>${products[i]?.unit}</td>
          <td>
            ${products[i]?.location_of_products?.map(res => (`<p style='border: 1px solid #dedede; border-radius: 5px; padding: 4px 6px'>
                ${res?.location?.title_en} <span style='font-size: 14px'>(${res?.amount})</span>
              </p>`)
            )}
          </td>
          <td>${products[i]?.location_of_products[0]?.rack || '-'}</td>
          <td>${products[i]?.backing}</td>
          <td>${products[i]?.master_backing}</td>
          <td>${products[i]?.manufacturer?.title_en}</td>
          <td>${products[i]?.country_of_origin}</td>
          <td>${products[i]?.manufacturer?.code}</td>
          <td>${products[i]?.supplier?.title_en}</td>
          <td>${products[i]?.supplier?.code}</td>
          <td>${products[i]?.cost}</td>
          <td>${products[i]?.price_one}</td>
          <td>${products[i]?.price_two}</td>
          <td>${products[i]?.price_three || '-'}</td>
        </tr>
          `
      }

      let htmlCode = `<html>
      <head>
        <title>${Translate[lang]?.invoice}</title>
        <style>
          *{
              margin: 0;
              padding: 0;
              box-sizing: border-box;
          }
          body{
            width: 99%;
            margin: auto;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }
        </style>
      </head>
      <body style="direction: ${lang==='en' ? 'ltr' : 'rtl'};">
      <div>
      <div style="margin-top: 3rem">
          <div style="background-color: rgb(222 222 222 / 21%); border: 1px solid #c3c1c1; border-radius: 12px; padding: 13px; text-align: center">
              <img src=${logo} alt="logo" style="width: 5rem;" />
          </div>

          <div style="margin: 1rem 0 3rem">
            <table>
              <tr>
                <th>ID</th>
                <th>${Translate[lang]?.title}</th>
                <th>${Translate[lang]?.quantity}</th>
                <th>${Translate[lang]?.unite}</th>
                <th>${Translate[lang]?.location}</th>
                <th>${Translate[lang]?.rack}</th>
                <th>${Translate[lang]?.backing}</th>
                <th>${Translate[lang]?.master_backing}</th>
                <th>${Translate[lang]?.manufacturer}</th>
                <th>${Translate[lang]?.country_of_origin}</th>
                <th>${Translate[lang]?.manufacturer} ID</th>
                <th>${Translate[lang]?.supplier}</th>
                <th>${Translate[lang]?.supplier_product} ID</th>
                <th>${Translate[lang]?.cost}</th>
                <th>${Translate[lang]?.selling_price_kwd}</th>
                <th>${Translate[lang]?.selling_price_two}</th>
                <th>${Translate[lang]?.selling_price_three}</th>
              </tr>
              ${productsCode}
            </table>
          </div>
      </div>
  </div>
      </body>
      </html>
      `;
      printWindow.document.write(htmlCode);
  
      printWindow.document.close();
  
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    };

    return(
        <>
        <div className="d-flex justify-content-between align-items-center mb-3 ">
          <div className="input-group w-50">
            <input 
                type="text" 
                style={{borderRadius: '8px',
                color: 'initial',
                padding: '18px 33px 18px 16px'}}
                className="form-control"
                placeholder={`${Translate[lang]?.search_by} I.D, ${Translate[lang]?.name}`}
                value={search}
                onChange={e=> setSearch(e.target.value) } 
            />
            <div className="flaticon-381-search-2"
              style={{position: 'absolute',zIndex:'1', right: lang === 'en' && '16px', left: lang === 'ar' && '16px', top: '50%', transform: 'translate(0, -50%)'}}
            ></div>
          </div>
          <div>
            <Button variant="secondary" className='mx-2 h-75' onClick={print}>
                {Translate[lang]?.print}
            </Button>
            {isExist('add_products') && <Button variant="primary" className='me-2 h-75' onClick={()=> navigate('/products/add-products')}>
                {Translate[lang]?.add} {Translate[lang]?.products}
            </Button>}
          </div>
        </div>

        <div className="mb-3">
            <Button variant={isDeleted ? 'secondary' : 'primary'} onClick={()=> setIsDeleted(false)}>
              {Translate[lang]?.active}
            </Button>
            <Button variant={!isDeleted ? 'secondary' : 'primary'} className='mx-2' onClick={()=> setIsDeleted(true)}>
              {Translate[lang]?.not_active}
            </Button>
        </div>

        <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
            {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.title}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.quantity}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.unite}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.location}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.rack}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.backing}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.master_backing}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.manufacturer}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.country_of_origin}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.manufacturer} ID</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.supplier}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.supplier_product} ID</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.cost}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.selling_price_kwd}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.selling_price_two}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.selling_price_three}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.description}</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((item, index)=>{
                    return <CardItem 
                    key= {index}
                    index= {index}
                    item={item}
                    setShouldUpdate={setShouldUpdate}
                    setIndexEdit={setIndexEdit}
                    indexEdit={indexEdit}
                    />
                  })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
              <Pagination
                  setData={setProducts}
                  service={productsService}
                  shouldUpdate={shouldUpdate}
                  setHasData={setHasData}
                  isDeleted={isDeleted}
                  setLoading={setLoading}
                  search={search}
                />
            </Card.Body>
          </Card>
        </>
    )
}
export default MasterHNProducts;