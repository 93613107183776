import { Button, Col, Modal, Row } from "react-bootstrap"
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";

const RejectedModal = ({addModal, setAddModal, item})=>{
    const lang = useSelector(state=> state.auth?.lang)

    return(
        <Modal className={lang === 'en' ? "en fade" : "ar fade"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
            <Modal.Header>
            <Modal.Title>Reason</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <p className='mb-0'>
                            {item?.reason}
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            </Modal.Footer>
        </Modal>)
}

export default RejectedModal;