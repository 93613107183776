import { API_BASE_URL_ENV } from "../jsx/common/common";
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/quotation/admin/ordersQuotation";

export default class OrderQuotationService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
}
