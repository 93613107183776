import { useEffect, useState } from "react";
import { Badge, Dropdown, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";
import MHProductsService from "../../../../services/MHProductsService";

const CardItem = ({item, index, setShouldUpdate,setIndexEdit, indexEdit}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [quantity, setQuantity] = useState(item.amount)
    
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const navigate = useNavigate()
    const productsService = new MHProductsService()

    const changeIsDeleted = ()=>{
      productsService.remove(item.id, { isDeleted: false }).then(res=>{
          if(res?.status === 200){
              setShouldUpdate(prev=> !prev)
              toast.success('Status Updated Successfully')
          }
      })
    }

    useEffect(()=>{
      setIsDeleted(item.isDeleted)
    },[item])

    const changeStatusToggle = (e) =>{
    //   productsService.remove(item.id, { isDeleted: !e.target.checked }).then(res=>{
    //     if(res?.status === 200){
    //         setShouldUpdate(prev=> !prev)
    //         toast.success('Status Updated Successfully')
    //     }
    // })
    }
    
    const updateQuantity = () =>{
      let data ={
        amount: parseInt(quantity)
      }
      // productsService.update(item.id, data)?.then(res=>{
      //   if(res.data?.status === 200){
      //       toast.success('Product Updated Successfully')
      //       setIndexEdit(null)
      //   }
      // })
    }

    return(
        <tr key={index} className='text-center product-row'>
                    <td>
                      <strong>{item.id}</strong>
                    </td>
                    <td className="showDes">{lang === 'en' ? item.name_en : item.name_ar}</td>
                    <td>
                      {item?.location_of_products?.map(res => res?.amount)?.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue;
                      }, 0)}
                    </td>
                    <td>{item?.unit || '-'}</td>
                    <td style={{
                      display: item?.location_of_products?.length > 1 ? "grid" : "revert",
                      gap: '8px',
                      gridTemplateColumns: "auto auto auto",
                    }}
                    >
                      {item?.location_of_products?.length ? 
                        item?.location_of_products?.map((res, ind) => {
                        return <Badge key={ind} variant="outline-success light">{res?.location?.title_en} {item?.location_of_products?.length > 1 ? <span style={{fontSize: '12px'}}>({res?.amount})</span> : ''}</Badge>
                      }) : '-'}
                    </td>
                    <td>{item?.location_of_products[0]?.rack || '-'}</td>
                    <td>{item?.backing || '-'}</td>
                    <td>{item?.master_backing || '-'}</td>
                    <td>{lang === 'en' ? item.manufacturer?.title_en : item?.manufacturer?.title_ar}</td>
                    <td>{item?.country_of_origin}</td>
                    <td>{item?.manufacturer?.code}</td>
                    <td>{lang === 'en' ? item?.supplier?.title_en : item?.supplier?.title_ar}</td>
                    <td>{item?.supplier?.code || '-'}</td>
                    <td>{item?.cost || '-'}</td>
                    <td>{item?.price_one || '-'}</td>
                    <td>{item?.price_two || '-'}</td>
                    <td>{item?.price_three || '-'}</td>
                    <td className="showDes">{lang === 'en' ? item?.description_en : item?.description_ar}</td>

                    <td>
                      {(isExist('edit_products') || isExist('view_products') || isExist('delete_products')) && <Dropdown>
                        <Dropdown.Toggle
                          className="light sharp i-false"
                        >
                          <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {isExist('view_products') && <Dropdown.Item onClick={()=>{
                            navigate(`/products/add-products/${item.id}`, {state: {view: true}})
                          }}>{Translate[lang]?.view}</Dropdown.Item>}
                          {isExist('edit_products') && <Dropdown.Item onClick={()=>{
                            navigate(`/products/add-products/${item.id}`, {state: {view: false}})
                          }}>{Translate[lang]?.edit}</Dropdown.Item>}
                          {(!isDeleted && isExist('delete_products')) && <Dropdown.Item onClick={()=> setDeleteModal(true)}>{Translate[lang]?.deactive}</Dropdown.Item>}
                        {(isDeleted && isExist('delete_products')) && <Dropdown.Item onClick={()=> changeIsDeleted()}>{Translate[lang]?.active}</Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>}
                    </td>
                    {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={lang==='en' ? item.name_en : item.name_ar}
                      deletedItem={item}
                      modelService={productsService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                      isDeleted={true}
                    />}
                  </tr>
    )
}
export default CardItem;