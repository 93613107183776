import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import CategoriesService from "../../../../services/CategoriesService";
import SuppliersService from "../../../../services/SuppliersService";

const AddSuppliersModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        name_en: '',
        name_ar: '',
        code: '',
        description_en: '',
        description_ar: '',
        categories: '',
        phone: '',
        website: '',
        location: '',
        contact: [""],
    })
    const [errors, setErrors] = useState({
        desc_ar: false,
        desc_en: false,
    });
    const [categoriesOptions, setCategoriesOptions] = useState([])
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const categoriesService = new CategoriesService()

    useEffect(() => {
        categoriesService.getList({isDeleted: false}).then((res) => {
            if (res?.status === 200) {
              let categories = res.data?.data?.data?.map((item) => {
                return {
                  id: item?.id,
                  value: item?.id,
                  label: lang === "en" ? item.name_en : item.name_ar,
                };
              });
              setCategoriesOptions(categories);
            }
          });
    }, [lang])

    useEffect(() => {
        if(Object.keys(item)?.length === 0){
            setIsAdd(true)
        } else {
            setIsAdd(false)
            setFormData({
                id: item?.id,
                name_en: item?.title_en,
                name_ar: item?.title_ar,
                contact: item?.contact || [""],
                location: item?.location,
                website: item?.website,
                code: item?.Code,
                description_ar: item?.description_ar,
                description_en: item?.description_en,
                category: item?.supplier_and_categories?.map(res=> {
                    return {
                        label: res?.category[`name_${lang}`],
                        value: res?.category?.id,
                        id: res?.category?.id
                    }
                })
            })
        }
    },[item, lang])

    const submit = () =>{
        if(!formData?.description_en || !formData?.description_ar){
            return
        }
        let data ={
            title_en: formData?.name_en,
            title_ar: formData?.name_ar,
            Code: formData?.code,
            category_id: formData?.category?.map(res=> res?.value),
            description_en: formData?.description_en,
            description_ar: formData?.description_ar,
            location: formData?.location,
            website: formData?.website,
            contact: formData?.contact,
        }
        if(isAdd){
            new SuppliersService().create(data)?.then(res=>{
                if(res && res?.status === 201){
                    toast.success('Supplier Added Successfully')
                    setAddModal()
                    setShouldUpdate(prev=> !prev)
                }
            })
        } else {
            new SuppliersService().update(formData?.id, data)?.then(res=>{
                if(res && res?.status === 200){
                    toast.success('Supplier Updated Successfully')
                    setAddModal()
                    setShouldUpdate(prev=> !prev)
                }
            })
        }
    }

    return(
        <Modal className={lang === 'en' ? "en fade" : "ar fade"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang]?.add : Translate[lang]?.edit} {Translate[lang]?.supplier}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                
                    <Row>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.english_title}
                                type='text'
                                placeholder={Translate[lang]?.english}
                                bsSize="lg"
                                name='name_en'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.name_en}
                                onChange={(e) => setFormData({...formData, name_en: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.arabic_title}
                                type='text'
                                placeholder={Translate[lang]?.arabic}
                                bsSize="lg"
                                name='name_ar'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.name_ar}
                                onChange={(e) => setFormData({...formData, name_ar: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <label className="text-label">
                            {Translate[lang]?.english_description}
                            </label>
                            <textarea
                            name="description_en"
                            style={{
                                minHeight: "80px",
                                maxHeight: "100px",
                                height: "100px",
                            }}
                            className="form-control"
                            required
                            placeholder={Translate[lang]?.english}
                            value={formData.description_en}
                            onChange={(e) => {
                                setErrors({
                                ...errors,
                                desc_en: false,
                                });
                                setFormData({...formData, description_en: e.target.value})
                            }}
                            rows="6"
                            ></textarea>
                            {errors["desc_en"] && (
                            <p className="text-danger m-0" style={{ fontSize: "12.8px" }}>
                                {Translate[lang].field_required}
                            </p>
                            )}
                        </Col>
                        <Col md={6} className="mb-3">
                            <label className="text-label">
                            {Translate[lang]?.arabic_description}
                            </label>
                            <textarea
                            name="description_ar"
                            style={{
                                minHeight: "80px",
                                maxHeight: "100px",
                                height: "100px",
                            }}
                            className="form-control"
                            placeholder={Translate[lang]?.arabic}
                            value={formData.description_ar}
                            onChange={(e) => {
                                setErrors({
                                ...errors,
                                desc_ar: false,
                                });
                                setFormData({...formData, description_ar: e.target.value})
                            }}
                            rows="6"
                            ></textarea>
                            {errors["desc_ar"] && (
                            <p className="text-danger m-0" style={{ fontSize: "12.8px" }}>
                                {Translate[lang].field_required}
                            </p>
                            )}
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.code}
                                type='text'
                                placeholder={Translate[lang]?.code}
                                bsSize="lg"
                                name='code'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.code}
                                onChange={(e) => setFormData({...formData, code: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.phone}
                                type='number'
                                placeholder={Translate[lang]?.phone}
                                bsSize="lg"
                                name='phone'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.phone}
                                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                            />
                        </Col>
                        <Col md={12} className="mb-3">
                            <label className="text-label">{Translate[lang]?.category}</label>
                            <Select
                                value={formData.category}
                                name="category"
                                isMulti
                                placeholder={Translate[lang]?.select}
                                options={categoriesOptions}
                                onChange={(e) => setFormData({...formData, category: e})}
                            />
                        </Col>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.location}
                                type='text'
                                placeholder={Translate[lang]?.location}
                                bsSize="lg"
                                name='location'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.location}
                                onChange={(e) => setFormData({...formData, location: e.target.value})}
                            />
                        </Col>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.website}
                                type='text'
                                placeholder={Translate[lang]?.website}
                                bsSize="lg"
                                name='website'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.website}
                                onChange={(e) => setFormData({...formData, website: e.target.value})}
                            />
                        </Col>
                        {formData?.contact?.map((cont, index) => {
                            return <React.Fragment key={index}>
                                <Col md={10}>
                                    <AvField
                                        label={`${Translate[lang]?.contact} ${index+1}`}
                                        type='number'
                                        placeholder={Translate[lang]?.contact}
                                        bsSize="lg"
                                        name={`contact${index}`}
                                        validate={{
                                            required: {
                                                value: index === 0,
                                                errorMessage: Translate[lang].field_required
                                            },
                                        }}
                                        value={cont}
                                        onChange={(e) => setFormData({...formData, website: e.target.value})}
                                    />
                                </Col>
                                {index+1 === formData?.contact?.length && <Col md={2}>
                                    <i 
                                        className="la la-plus-circle position-absolute"
                                        style={{
                                            fontSize: '2.5rem',
                                            top: '30px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={()=> setFormData({...formData, contact: [...formData.contact, ""]})}
                                    ></i>
                                </Col>}
                            </React.Fragment>
                        })}
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddSuppliersModal;