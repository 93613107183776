import { useEffect, useState } from "react"
import ClientsService from "../../../services/ClientsService"
import { toast } from "react-toastify"
import { Button, Card } from "react-bootstrap"
import { Translate } from "../../Enums/Tranlate"
import { useSelector } from "react-redux"
import { AvForm } from "availity-reactstrap-validation"
import BaseService from "../../../services/BaseService"
import Loader from "../../common/Loader"
import uploadImg from "../../../images/upload-img.png";
import "./style.scss"

const Clients = () => {
    const [formData, setFormData] = useState({
        images: [
            { src: "", loading: false },
            { src: "", loading: false },
            { src: "", loading: false },
            { src: "", loading: false },
            { src: "", loading: false },
        ]
    })
    const [loading, setLoading] = useState(false)
    const clientsService = new ClientsService()
    const lang = useSelector((state) => state.auth?.lang);

    useEffect(()=> {
        let clientsReq = clientsService?.getList()
    
        Promise.all([clientsReq]).then(res=>{
          let data = {}

          if(res[0]?.status === 200){
            if(res[0]?.data?.data?.length > 5){
              data['images'] = res[0]?.data?.data?.map(cli => {
                return {id: cli?.id, src: cli?.image, loading: false }
              })
            } else {
              data['images'] = Array.from({ length: 5 }, (_, index) => {
                let arr = res[0]?.data?.data;
                if(arr[index]){
                  return {id: arr[index]?.id, src: arr[index]?.image, loading: false }
                } else {
                  return { src: "", loading: false }
                }
              })
            }
          }
          setFormData({...data})
        }).catch(error => {
          console.error(error);
        });
    }, [])

    const handleClients = (e) =>{
        e.preventDefault();
    
        setLoading(true);
        let data = {
          images: formData?.images?.filter(res=> !!res.src)?.map(res=> res?.src)
        };
    
        clientsService.create(data)?.then((res) => {
          if (res?.status === 201) {
            toast.success("Clients Added Successfully");
          }
          setLoading(false);
        }).catch(()=> setLoading(false));
    }

    const deleteClientImg = (id) =>{  
        setLoading(true);
    
        clientsService.remove(id)?.then((res) => {
          if (res?.status === 200) {
            toast.success("Image Removed Successfully");
            let update = formData?.images?.map((data, ind)=> {
              if(data.id === id){
                return {src: "", loading: false}
              } else{
                 return data
              }
            })
            setFormData({ images: update });
          }
          setLoading(false);
        }).catch(()=> setLoading(false));
    }

    const fileHandler = (e, index, type) => {
        if(e.target.files?.length === 0){
        return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);
        if(type === "clients"){
        let update = formData?.images?.map((data, ind)=> {
            if(ind === index){
            return {src: "", loading: true}
            } else{
            return data
            }
        })
        setFormData({ ...formData,  images: update });
        }
        new BaseService().postUpload(filesData[0]).then((res) => {
        if (res?.data?.status) {
            let update = formData?.images?.map((data, ind)=> {
                if(ind === index){
                return {src: res.data.url, loading: false}
                } else{
                return data
                }
            })
            setFormData({ 
                images: update
            });
        }
        });
    };
    return <Card>
    <Card.Body>
      <AvForm onValidSubmit={handleClients}>
       <div className="our-clients mt-3">
          <h3>{Translate[lang]?.our_clients}</h3>
          <div className="row mt-4">
              {formData?.images?.map((client, index)=> {
                  return <div className="col-lg-2 col-sm-4 mb-3" key={index}>
                  <div className="image-placeholder">
                    <div className="avatar-edit w-100 h-100">
                      <input
                        type="file"
                        className="w-100 h-100 d-block cursor-pointer"
                        style={{opacity: '0'}}
                        onChange={(e) => fileHandler(e, index, 'clients')}
                        id={`imageUpload${index}`}
                      />
                    </div>
                    {!!client?.id && <button
                      className="delete-img"
                      type="button"
                      style={{
                        left: lang === 'ar' ? '-13px' : "auto",
                        right: lang === 'en' ? '-13px' : "auto",
                      }}
                      onClick={() => deleteClientImg(client?.id)}
                    >
                      <i className="la la-trash"></i>
                    </button>}
                    <div className="avatar-preview">
                      {!!client.src ? (
                        <div id={`imagePreview${index}`}>
                          <img
                            id={`saveImageFile${index}`}
                            src={client?.src}
                            alt="icon"
                          />
                        </div>
                      ) : (
                        <div id={`imagePreview${index}`}>
                          {(!client.src && client.loading)  && <Loader></Loader>}
                          {(!client?.src && !client.loading) && (
                            <img
                              id={`saveImageFile${index}`}
                              src={uploadImg}
                              alt="icon"
                              style={{
                                width: "60px",
                                height: "60px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              })}
              <div className="col-lg-2 col-sm-4 d-flex" style={{
                  alignItems: "center", justifyContent: "center"
              }}>
                  <div className="add-client">
                      <i className="la la-plus cursor-pointer" onClick={()=>{
                          setFormData({images: [...formData.images, { src: "", loading: false }]})
                      }}></i>
                  </div>
              </div>
          </div>
       </div>

       <div className="d-flex justify-content-between mt-4">
          <Button variant="primary" type="submit" disabled={loading}>{Translate[lang]?.submit}</Button>
       </div>
       </AvForm>
    </Card.Body>
  </Card>
}
export default Clients