import React, { useEffect } from "react";
import { Routes, Route, Outlet, useLocation, useNavigate } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Error404 from "./common/Error404";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";

import Admins from "./pages/Admin";
import AddAdmin from "./pages/Admin/AddAdmin";
import Permission from "./pages/CustomRules";
import Profile from "./pages/Profile";
import ContactUs from "./pages/ContactUs";
import Products from "./pages/Products";
import AddProducts from "./pages/Products/AddProducts";
import Categories from "./pages/Categories";
import Static from "./pages/StaticPages/Static";
import DynamicVariant from "./pages/DynamicVariant";
import AddDynamicVariant from "./pages/DynamicVariant/AddVariant";
import Variant from "./pages/Variant";
import AddVariant from "./pages/Variant/AddVariant";
import Notification from "./pages/Notification";
import AddNotification from "./pages/Notification/AddNotification";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/Orders/Details";
import Invoice from "./pages/Orders/Invoice";
import PromCodes from "./pages/PromoCodes";
import AddPromoCodes from "./pages/PromoCodes/AddPromoCodes";
import Banners from "./pages/Banners";
import Delivery from "./pages/Delivery";
import Payment from "./pages/Payment";
import SocialMedia from "./pages/SocialMedia";
import Currency from "./pages/Currency";
import Home from "./pages/Dashboard";
import Users from "./pages/Users";
import UserProfile from "./pages/Users/Profile";
import Brands from "./pages/Brands";
import OrdersGuest from "./pages/OrdersGuest";
import OrderGuestDetails from "./pages/OrdersGuest/Details";
import InvoiceGuest from "./pages/OrdersGuest/Invoice";
import Suppliers from "./pages/Suppliers";
import Quotations from "./pages/Quotations";
import Location from "./pages/Location";
import Manufacturer from "./pages/Manufacturer";
import Clients from "./pages/Clients";
import Quotation from "./pages/Quotations/Quotation";
import OrderQuotations from "./pages/OrderQuotations";
import QuotationDetails from "./pages/OrderQuotations/Quotation";

const Markup = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(()=>{
    if(location?.pathname === "/login"){
      navigate('/')
    }
  },[location])

  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    // Admins
    { url: "admins", component: <Admins /> },
    { url: "admins/add-admins", component: <AddAdmin /> },
    { url: "admins/edit-admin/:id/:name", component: <AddAdmin /> },

    // Users
    { url: "users", component: <Users /> },
    { url: "users/profile", component: <UserProfile /> },

    // Rules
    { url: "rules", component: <Permission /> },
    { url: "rules/:id", component: <Permission /> },

    // Home
    { url: "contact-us", component: <ContactUs /> },

    // Suppliers
    { url: "suppliers", component: <Suppliers /> },

    // Location
    { url: "location", component: <Location /> },

    // Manufacturer
    { url: "manufacturer", component: <Manufacturer /> },
    { url: "quotations", component: <Quotations /> },
    { url: "quotation", component: <Quotation /> },
    { url: "order-quotations", component: <OrderQuotations /> },
    { url: "order-quotations/details", component: <QuotationDetails /> },

    // Products
    { url: "products", component: <Products /> },
    { url: "products/add-products", component: <AddProducts /> },
    { url: "products/add-products/:id", component: <AddProducts /> },
  
    { url: "categories", component: <Categories /> },

    { url: "brands", component: <Brands /> },

    { url: "contact-us", component: <ContactUs /> },

    //  => DynamicVariant
    { url: "dynamic-variant", component: <DynamicVariant /> },
    {
      url: "dynamic-variant/add-dynamic-variant",
      component: <AddDynamicVariant />,
    },
    {
      url: "dynamic-variant/edit-dynamic-variant/:id",
      component: <AddDynamicVariant />,
    },

    //  => Variant
    { url: "variant", component: <Variant /> },
    { url: "variant/add-variant", component: <AddVariant /> },
    { url: "variant/add-variant/:id", component: <AddVariant /> },

    //  => Notification
    { url: "notification", component: <Notification /> },
    { url: "notification/add-notification", component: <AddNotification /> },

    //  => Orders
    { url: "orders", component: <Orders /> },
    // { url: "orders/add-orders", component: <AddOrders /> },
    { url: "orders/details", component: <OrderDetails /> },
    { url: "orders/invoice", component: <Invoice /> },

    //  => Orders Guest
    { url: "orders-guest", component: <OrdersGuest /> },
    { url: "orders-guest/details", component: <OrderGuestDetails /> },
    { url: "orders-guest/invoice", component: <InvoiceGuest /> },

    //  => Promo Codes
    { url: "promo-codes", component: <PromCodes /> },
    { url: "promo-codes/add-promo-codes", component: <AddPromoCodes /> },
    { url: "promo-codes/edit-promo-codes", component: <AddPromoCodes /> },

    //  => Banners
    { url: "banners", component: <Banners /> },

    //  => Setting
    { url: "social", component: <SocialMedia /> },
    { url: "currency", component: <Currency /> },
    { url: "about-us", component: <Static /> },
    { url: "terms", component: <Static /> },
    { url: "values", component: <Static /> },
    { url: "company", component: <Static /> },
    { url: "clients", component: <Clients /> },
    { url: "privacy", component: <Static /> },
    { url: "delivery", component: <Delivery /> },
    { url: "payment", component: <Payment /> },

    //Profile
    { url: "profile", component: <Profile /> },

    // Error
    { url: "*", component: <Error404 /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className={`show `}>
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
