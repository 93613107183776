export const Rules = [
  { label: "Admin", value: "admin" },
  { label: "Rules", value: "rules" },
  {label: 'Social Media', value: 'social_media'},
  { label: "Banners", value: "banners" },
  { label: "Products", value: "products" },
  {label: 'Order', value: 'order'},
  {label: 'Promo Code', value: 'promo_code'},
  {label: 'Categories', value: 'categories'},
  {label: 'Brands', value: 'brands'},
  {label: 'Variant', value: 'variant'},
  {label: 'Static Pages', value: 'static_pages'},
  {label: 'Delivery', value: 'delivery'},
];

export const customRules= [
  {
    label: "admins",
    rules:[
      {label: "Add", value: 'add_admins'},
      {label: "Edit", value: 'edit_admins'},
      {label: "View", value: 'view_admins'},
      {label: "Delete", value: 'delete_admins'},
    ]
  }, {
    label: "rules",
    rules:[
      {label: "Edit", value: 'edit_rules'},
      {label: "View", value: 'view_rules'},
    ]
  }, {
    label: "categories",
    rules:[
      {label: "Add", value: 'add_categories'},
      {label: "Edit", value: 'edit_categories'},
      {label: "View", value: 'view_categories'},
      {label: "Delete", value: 'delete_categories'},
    ]
  }, {
    label: "brands",
    rules:[
      {label: "Add", value: 'add_brands'},
      {label: "Edit", value: 'edit_brands'},
      {label: "View", value: 'view_brands'},
      {label: "Delete", value: 'delete_brands'},
    ]
  }, {
    label: "products",
    rules:[
      {label: "Add", value: 'add_products'},
      {label: "Edit", value: 'edit_products'},
      {label: "View", value: 'view_products'},
      {label: "Delete", value: 'delete_products'},
    ]
  }, {
    label: "variant",
    rules:[
      {label: "Add", value: 'add_variant'},
      {label: "Edit", value: 'edit_variant'},
      {label: "View", value: 'view_variant'},
      {label: "Delete", value: 'delete_variant'},
    ]
  }, {
    label: "orders",
    rules:[
      // {label: "Add", value: 'add_orders'},
      {label: "Edit", value: 'edit_orders'},
      {label: "View", value: 'view_orders'},
      // {label: "Delete", value: 'delete_orders'},
    ]
  }, {
    label: "orders_guest",
    rules:[
      // {label: "Add", value: 'add_orders_guest'},
      {label: "Edit", value: 'edit_orders_guest'},
      {label: "View", value: 'view_orders_guest'},
      // {label: "Delete", value: 'delete_orders_guest'},
    ]
  }, {
    label: "promo_codes",
    rules:[
      {label: "Add", value: 'add_promo_codes'},
      {label: "Edit", value: 'edit_promo_codes'},
      {label: "View", value: 'view_promo_codes'},
      {label: "Delete", value: 'delete_promo_codes'},
    ]
  }, {
    label: "suppliers",
    rules:[
      {label: "Add", value: 'add_suppliers'},
      {label: "Edit", value: 'edit_suppliers'},
      {label: "View", value: 'view_suppliers'},
      {label: "Delete", value: 'delete_suppliers'},
    ]
  }, {
    label: "manufacturer",
    rules:[
      {label: "Add", value: 'add_manufacturer'},
      {label: "Edit", value: 'edit_manufacturer'},
      {label: "View", value: 'view_manufacturer'},
      // {label: "Delete", value: 'delete_manufacturer'},
    ]
  }, {
    label: "quotations",
    rules:[
      // {label: "Add", value: 'add_quotations'},
      {label: "Edit", value: 'edit_quotations'},
      {label: "View", value: 'view_quotations'},
      {label: "Delete", value: 'delete_quotations'},
    ]
  }, {
    label: "order_quotations",
    rules:[
      {label: "View", value: 'view_order_quotations'}
    ]
  }, {
    label: "location",
    rules:[
      {label: "Add", value: 'add_location'},
      {label: "Edit", value: 'edit_location'},
      {label: "View", value: 'view_location'},
      // {label: "Delete", value: 'delete_location'},
    ]
  }, {
    label: "banners",
    rules:[
      {label: "Add", value: 'add_banners'},
      {label: "View", value: 'view_banners'},
    ]
  }, {
    label: "contact_us",
    rules:[
      {label: "Edit", value: 'edit_contact_us'},
      {label: "View", value: 'view_contact_us'},
      {label: "Delete", value: 'delete_contact_us'},
    ]
  }, {
    label: "delivery",
    rules:[
      {label: "Add", value: 'add_delivery'},
      {label: "View", value: 'view_delivery'},
    ]
  }, {
    label: "social_media",
    rules:[
      {label: "Add", value: 'add_social_media'},
      {label: "View", value: 'view_social_media'},
    ]
  }, {
    label: "payment",
    rules:[
      {label: "Add", value: 'add_payment'},
      {label: "View", value: 'view_payment'},
    ]
  }, {
    label: "clients",
    rules:[
      {label: "Add", value: 'add_clients'},
      {label: "View", value: 'view_clients'},
    ]
  }, {
    label: "about_us",
    rules:[
      {label: "Add", value: 'add_about_us'},
      {label: "View", value: 'view_about_us'},
    ]
  }, {
    label: "privacy",
    rules:[
      {label: "Add", value: 'add_privacy'},
      {label: "View", value: 'view_privacy'},
    ]
  }, {
    label: "company",
    rules:[
      {label: "Add", value: 'add_company'},
      {label: "View", value: 'view_company'},
    ]
  }, {
    label: "values",
    rules:[
      {label: "Add", value: 'add_values'},
      {label: "View", value: 'view_values'},
    ]
  }, {
    label: "terms_and_conditions",
    rules:[
      {label: "Add", value: 'add_terms_and_conditions'},
      {label: "View", value: 'view_terms_and_conditions'},
    ]
  }, {
    label: "dashboard",
    rules:[
      {label: "View", value: 'view_dashboard'},
    ]
  }, {
    label: "users",
    rules:[
      {label: "View", value: 'view_users'},
    ]
  },
]
