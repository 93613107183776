import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import LocationService from "../../../../services/LocationService";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";

const AddLocationModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        title_en: '',
        title_ar: '',
        priority: ""
    })
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const locationService = new LocationService()

    useEffect(() => {
        if(Object.keys(item)?.length === 0){
            setIsAdd(true)
        } else {
            setIsAdd(false)
            setFormData({
                id: item?.id,
                title_en: item?.title_en,
                title_ar: item?.title_ar,
                priority: item?.priority,
            })
        }
    },[item])


    const submit = () =>{
        let data ={
            title_en: formData?.title_en,
            title_ar: formData?.title_ar,
            priority: formData?.priority
        }
        setLoading(true)
        if(isAdd){
            locationService.create(data)?.then(res=>{
                if(res && res?.status === 201){
                    toast.success('Location Added Successfully')
                    setAddModal()
                    setShouldUpdate(prev=> !prev)
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        } else {
            locationService.update(formData?.id, data)?.then(res=>{
                if(res && res?.status === 200){
                    toast.success('Location Updated Successfully')
                    setAddModal()
                    setShouldUpdate(prev=> !prev)
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    }

    return(
        <Modal className={lang === 'en' ? "en fade" : "ar fade"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang]?.add : Translate[lang]?.edit} {Translate[lang]?.location}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                
                    <Row>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.english_title}
                                type='text'
                                placeholder={Translate[lang]?.english}
                                bsSize="lg"
                                name='title_en'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.title_en}
                                onChange={(e) => setFormData({...formData, title_en: e.target.value})}
                            />
                        </Col>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.arabic_title}
                                type='text'
                                placeholder={Translate[lang]?.arabic}
                                bsSize="lg"
                                name='title_ar'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.title_ar}
                                onChange={(e) => setFormData({...formData, title_ar: e.target.value})}
                            />
                        </Col>
                        <Col md={12}>
                            <AvField
                                label={Translate[lang]?.priority}
                                type='number'
                                placeholder={Translate[lang]?.priority}
                                bsSize="lg"
                                min='0'
                                name='priority'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    },
                                }}
                                value={formData.priority}
                                onChange={(e) => setFormData({...formData, priority: e.target.value})}
                            />
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddLocationModal;