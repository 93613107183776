import { useEffect, useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";
import SuppliersService from "../../../../services/SuppliersService";

const CardItem = ({item, setItem, index, setAddModal, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const suppliersService = new SuppliersService()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>{lang === 'en' ? item?.title_en : item?.title_ar}</td>
            <td>{item?.Code}</td>
            <td style={{display: 'grid', gap:'10px',gridTemplateColumns: 'auto auto auto'}}>
                {item.supplier_and_categories?.map((category, index)=>{
                    return <Badge key={index} variant="outline-primary light">
                      {lang === 'en' ? category?.category?.name_en : category?.category?.name_ar}
                    </Badge>
                })}
            </td>
            <td>
                {(isExist('edit_suppliers') || isExist('delete_suppliers')) && <Dropdown>
                    <Dropdown.Toggle className="light sharp i-false">
                        <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isExist('edit_suppliers') && <Dropdown.Item onClick={()=> {
                            setItem(item)
                            setAddModal(true)
                        }}> {Translate[lang]?.edit}</Dropdown.Item>}
                        {isExist('delete_suppliers') && <Dropdown.Item onClick={()=> setDeleteModal(true)}>{Translate[lang]?.delete}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </td>

            {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={lang==='en' ? item.title_en : item.title_ar}
                      deletedItem={item}
                      modelService={suppliersService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                      isDeleted={true}
                    />}
            </tr>
    )
}
export default CardItem;