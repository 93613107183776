import { useState } from "react";
import { Badge} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import { Link } from "react-router-dom";
import RejectedModal from "../RejectedModal";

const CardItem = ({item, index}) =>{
    const [addModal, setAddModal] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>{item?.user?.company_name}</td>
            <td>{item?.user?.email}</td>
            <td>{item?.user?.phone}</td>
            <td>{item?.reference_id}</td>
            <td>
                <Badge 
                    variant={
                        item?.esen_status === 'provided' ? 'secondary' : 'primary'
                    }
                    className='text-capitalize'
                >
                    {item?.esen_status}
                </Badge>
            </td>
            <td>
                <Badge 
                    variant={
                        item?.user_status === 'new' ? 'primary' : 
                        item?.user_status === 'pending' ? 'secondary' : 
                        item?.user_status === "rejected" ? 'danger' : 'success'
                    }
                    style={{
                        cursor: item?.user_status === "rejected" ? 'pointer' : 'default'
                    }}
                    onClick={() => {
                        if(item?.user_status === "rejected"){
                            setAddModal(true)
                        }
                    }}
                >
                    {Translate[lang][item?.user_status]}
                </Badge>
            </td>
            <td>
                <Link to='/quotation' state={item}>
                    <i className="la la-eye" style={{fontSize: '27px'}}></i>
                </Link>
            </td>

            {addModal && <RejectedModal
                addModal={addModal}
                setAddModal={()=> setAddModal(false)}
                item={item}
            />}
        </tr>
    )
}
export default CardItem;