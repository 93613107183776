import { Card, Col, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Translate } from "../../Enums/Tranlate";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const QuotationDetails = () => {
    const [data, setData] = useState({})
    const lang = useSelector(state=> state.auth?.lang)
    const location = useLocation()

    useEffect(()=> {
        let info = location?.state
        setData(info)
    }, [location])

    return <Card>
        <Card.Body>
            <div>
                <Table responsive className="mb-0">
                    <thead>
                    <tr className='text-center'>
                        <th>
                        <strong>I.D</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.name}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.unite}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.quantity}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.selling_price_two}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.total}</strong>
                        </th>
                    </tr>
                    </thead>

                    <tbody className="table-body">
                        {data?.sub_quotations?.map((item, index) =>{
                            return <tr key={index} className='text-center'>
                                <td>
                                    <strong>{item.id}</strong>
                                </td>
                                <td>{lang === 'en' ? item?.product?.name_en : item?.product?.name_ar}</td>
                                <td>{item?.product?.unit}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.price.toFixed(3)}</td>
                                <td>
                                    {(item?.amount*item?.price).toFixed(3)}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
                <Row>
                    <Col md={3} className="d-flex align-items-center" style={{gap: '12px'}}>
                        <label className="mb-0">
                            {Translate[lang]?.total}:
                        </label>
                        <p className="mb-0 text-primary">
                            {(data?.sub_quotations?.map(res=> res?.amount*res?.price).reduce((acc, currentValue) => acc + currentValue, 0))?.toFixed(3)} {Translate[lang].kwd}
                        </p>
                    </Col>
                    <Col md={3} className="d-flex align-items-center" style={{gap: '12px'}}>
                        <label className="mb-0">
                            {Translate[lang]?.validity}:
                        </label>
                        <p className="mb-0 text-black">{data?.quotation?.validity}</p>
                    </Col>
                    <Col md={3} className="d-flex align-items-center" style={{gap: '12px'}}>
                        <label className="mb-0">
                            {Translate[lang]?.delivery}:
                        </label>
                        <p className="mb-0 text-black">{data?.quotation?.delivery}</p>
                    </Col>
                    <Col md={3} className="d-flex align-items-center" style={{gap: '12px'}}>
                        <label className="mb-0">
                            {Translate[lang]?.payment}:
                        </label>
                        <p className="mb-0 text-black">{data?.quotation?.payment}</p>
                    </Col>
                </Row>
              </div>
        </Card.Body>
    </Card>
}
export default QuotationDetails;