import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import uploadImg from "../../../../images/upload-img.png";
import { toast } from "react-toastify";
import BaseService from "../../../../services/BaseService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import { Translate } from "../../../Enums/Tranlate";
import CategoriesService from "../../../../services/CategoriesService";
import MHProductsService from "../../../../services/MHProductsService";
import VariantService from "../../../../services/VariantService";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import BrandsService from "../../../../services/BrandsService";
import LocationService from "../../../../services/LocationService";
import ManufacturerService from "../../../../services/ManufacturerService";
import SuppliersService from "../../../../services/SuppliersService";
import "../style.scss";

const AddProducts = () => {
  const [product, setProduct] = useState({
    name_en: "",
    name_ar: "",
    amount: "",
    manufacturer: "",
    country_of_origin: "",
    description_en: "",
    description_ar: "",
    bestSeller: false,
    newIn: false,
    offer: false,
    offerPrice: "",
    unite: "",
    price_one: "",
    price_two: "",
    price_three: "",
    category: "",
    brand: "",
    code: "",
    supplier: "",
    variant: [],
    cost: "",
    images: [{ src: "" }, { src: "" }, { src: "" }, { src: "" }, { src: "" }],
  });
  const [locationQuantity, setLocationQuantity] = useState([
    {location: "", quantity: "", rack: ""}
  ])
  const [errors, setErrors] = useState({
    desc_ar: false,
    desc_en: false,
    images: 0,
  });
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [loading, setLoadning] = useState(false);
  const [imagesForAll, setImagesForAll] = useState(false);
  const [productForAll, setProductForAll] = useState(true);
  const [costForAll, setCostForAll] = useState(true);
  const [hasVariant, setHasVariant] = useState(false);
  const [view, setView] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [staticVariantValue, setStaticVariantValue] = useState({});
  const [variant, setVariant] = useState([]);
  const [customVariant, setCustomVariant] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [files, setFiles] = useState([{}, {}, {}, {}, {}]);
  const [pdfFile, setPdfFile] = useState({src: '', loading: ''});
  const categoriesService = new CategoriesService();
  const brandsService = new BrandsService()
  const locationService = new LocationService()
  const productsService = new MHProductsService();
  const manufacturerService = new ManufacturerService();
  const suppliersService = new SuppliersService();
  const Auth = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.auth.lang);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    categoriesService.getList().then((res) => {
      if (res?.status === 200) {
        let categories = res.data?.data?.data?.map((item) => {
          return {
            id: item?.id,
            value: item?.id,
            label: lang === "en" ? item.name_en : item.name_ar,
          };
        });
        setCategoriesOptions(categories);
      }
    });
    brandsService.getList().then((res) => {
      if (res?.status === 200) {
        let brands = res.data?.data?.data?.map((item) => {
          return {
            id: item?.id,
            value: item?.id,
            label: lang === "en" ? item.name_en : item.name_ar,
          };
        });
        setBrandsOptions(brands);
      }
    });
    locationService.getList().then((res) => {
      if (res?.status === 200) {
        let locationData = res.data?.data?.data?.map((item) => {
          return {
            id: item?.id,
            value: item?.id,
            label: lang === "en" ? item.title_en : item.title_ar,
          };
        });
        setLocationOptions(locationData);
      }
    });
    manufacturerService.getList().then((res) => {
      if (res?.status === 200) {
        let manufacturerData = res.data?.data?.data?.map((item) => {
          return {
            id: item?.id,
            value: item?.id,
            label: lang === "en" ? item.title_en : item.title_ar,
          };
        });
        setManufacturerOptions(manufacturerData);
      }
    });
    suppliersService.getList().then((res) => {
      if (res?.status === 200) {
        let supplierData = res.data?.data?.data?.map((item) => {
          return {
            id: item?.id,
            value: item?.id,
            label: lang === "en" ? item.title_en : item.title_ar,
          };
        });
        setSupplierOptions(supplierData);
      }
    });
  }, [lang]);

  useEffect(() => {
    if (!!product?.category) {
      new VariantService().getVariant(product?.category?.id)?.then((res) => {
        if (res?.data?.status === 200) {
          if(res?.data?.data.data?.length > 0){
            setHasVariant(true)
            setImagesForAll(false)
            setProductForAll(false)
            setCostForAll(false)
          } else {
            setHasVariant(false)
            setImagesForAll(true)
            setProductForAll(true)
            setCostForAll(true)
          }
          let custom = res.data?.data.data?.reduce((result, item) => {
            result[item.name_en] = '';
            return result;
          }, {})
          if(!id) setCustomVariant([{
            ...custom,
            locationQuantity: [{location:"", rack:"", quantity:""}],
            images: [{}, {}, {}, {}, {}]
          }])
          setStaticVariantValue(custom)
          setVariant(res.data?.data.data);
        }
      });
    }
  }, [product?.category]);

  useEffect(() => {
    setView(location?.state?.view)

    let prod_id = window.location.pathname.split("/products/add-products/")[1];
    setId(Number(prod_id));

    if (!!prod_id) {
      let obj = {}
      let prod = productsService.getProduct(prod_id)
      dispatch(loadingToggleAction(true));
      Promise.all([prod]).then(res=>{
        let data = {}
        if (res[0]?.status === 200) {
          let response = res[0].data?.data;
          data = {
            ...response?.product,
            offerPrice: !!response.product.offerPrice
              ? Number(response.product.offerPrice)
              : "",
            category: {
              ...response?.product.category,
              id: response?.product.category_id,
              value: response.product?.category_id,
              label:
                lang === "en"
                  ? response.product?.category?.name_en
                  : response.product?.category?.name_ar,
            },
            brand: !!response?.product.brand ? {
              ...response?.product.brand,
              id: response?.product.brand_id,
              value: response.product?.brand_id,
              label:
                lang === "en"
                  ? response.product?.brand?.name_en
                  : response.product?.brand?.name_ar,
            } : '',
            images: product?.images?.map((_, index) => {
              if( response?.product?.product_images[index]?.url?.endsWith('.pdf') ||
                response?.product?.product_images[index]?.url?.endsWith('.doc') ||
                response?.product?.product_images[index]?.url?.endsWith('.docx')
              ){
                return setPdfFile({
                  src: response?.product?.product_images[index]?.url, 
                  loading: false
                })
                
              }
              if (!!response.product?.product_images[index]?.url) {
                return {
                  src: response.product.product_images[index]?.url,
                };
              } else {
                return {
                  src: "",
                };
              }
            }),
            manufacturer: {
              ...response.product?.manufacturer,
              label: lang === "en"
              ? response.product?.manufacturer?.title_en
              : response.product?.manufacturer?.title_ar,
              value: response.product?.manufacturer_id,
              id: response.product?.manufacturer_id
            },
            supplier: {
              ...response.product?.supplier,
              label: lang === "en"
              ? response.product?.supplier?.title_en
              : response.product?.supplier?.title_ar,
              value: response.product?.supplier_id,
              id: response.product?.supplier_id
            },
            unite: response.product?.unit,
            variant: response.product?.variant?.map((item) => {
              obj[`${item.variant?.name_en}`] = {
                id: item?.variant_value?.id,
                variant_id: item.variant?.id,
                label: item.variant_value?.value_en,
                value: item.variant_value?.value_en,
                value_ar: item.variant?.name_ar,
                value_en: item.variant?.name_en,
              }
              return {
                name_ar: item.variant?.name_ar,
                name_en: item.variant?.name_en,
                variant_id: item.variant?.id,
                variant_value_id: item?.variant_value?.id,
                variant_values: { ...item.variant_value },
              };
            }),
          };
        }

        setProduct({ ...data });
        dispatch(loadingToggleAction(false));

        let locQu = res[0].data?.data.product?.location_of_products?.map(lq => {
          return {
            location: {
              label: lq?.location?.title_en,
              value: lq?.location?.id,
              id: lq?.location?.id,
            },
            quantity: lq?.amount,
            rack: lq?.rack
          }
        })
        setLocationQuantity(locQu)
        
        setProductForAll(true)
        setCustomVariant([{ 
          ...obj, 
          price_one: res[0].data?.data?.product?.price_one, 
          price_two: res[0].data?.data?.product?.price_two, 
          price_three: res[0].data?.data?.product?.price_three ,
          cost: res[0].data?.data?.product?.cost ,
        }])
      })
    }
  }, []);

  const fileHandler = (e, index) => {
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);
    let update = files?.map((file, updateIndex) => {
      if (updateIndex === index) {
        return e.target.files[0];
      } else {
        return file;
      }
    });
    new BaseService().postUpload(filesData[0]).then((res) => {
      if (res?.data?.status) {
        let updateImages = product?.images.map((item, ind) => {
          if (ind === index) {
            return { src: res.data.url };
          } else {
            return { ...item };
          }
        });
        setProduct({ ...product, images: updateImages });
        setFiles([...update]);
      }
    });
    setTimeout(function () {
      setProduct({ ...product, images: update });
    }, 200);
  };

  const pdfFileHandler = (e) => {
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);
    if(!filesData?.length){
      return
    }
    setPdfFile({src: '', loading: true})
    new BaseService().postUpload(filesData[0]).then((res) => {
      if (res?.data?.status) {
        setPdfFile({src: res.data.url, loading: false})
      }
    });
  };

  const variantFileHandler = (e, imageIndex, customVariantIndex) => {
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);

    new BaseService().postUpload(filesData[0]).then((res) => {
      if (res?.data?.status) {
        let update = customVariant.map((item, ind) => {
          if (ind === customVariantIndex) {
            return {
              ...item,
              images: item?.images?.map((img, imgInd)=>{
              if(imgInd === imageIndex){
                return { src: res.data.url }
              } else {
                return img
              }
            })}
          } else {
            return { ...item };
          }
        });
        setCustomVariant(update);
      }
    });
  };

  const handlerText = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const submit = () => {
    if (!product.description_ar || !product.description_en || 
        !product?.supplier || !product.manufacturer ||
        (imagesForAll && product?.images?.filter((res) => !!res?.src)?.length === 0)
    ) {
      if (!product.description_ar || !product.description_en) toast.error("Enter Description")
      if (!product?.supplier) toast.error("Select Supplier")
      if (!product.manufacturer) toast.error("Select Manufacturer")
      if (imagesForAll && product?.images?.filter((res) => !!res?.src)?.length === 0) toast.error("Upload Images");
      return;
    }

    if (!id && !imagesForAll && customVariant?.map(obj =>
          obj.images.every(obj => Object.keys(obj).length === 0)
        )?.includes(true)) {
      toast.error("Upload Images in Cards");
      return;
    }

    setLoadning(true);
    let data = {
      name_en: product.name_en,
      name_ar: product.name_ar,
      code: product.code,
      unit: product.unite,
      country_of_origin: product.country_of_origin,
      category_id: product.category?.value,
      supplier_id: product.supplier?.value,
      manufacturer_id: product.manufacturer?.value,
      description_en: product.description_en,
      description_ar: product.description_ar,
      bestSeller: product.bestSeller,
      newIn: product.newIn,
      offer: product.offer,
      images: product?.images?.filter((res) => !!res?.src)?.map((item) => item?.src),
    };
    if(!!product?.brand) data['brand_id'] = product?.brand?.value
    if(!!product?.backing) data['backing'] = product?.backing
    if(!!product?.master_backing) data['master_backing'] = product?.master_backing
    if(productForAll) {
      data['price_one'] = parseFloat(product?.price_one)
      data['price_two'] = parseFloat(product?.price_two)
      if(!!product?.price_three) data['price_three'] = parseFloat(product?.price_three)
    }
    if(!hasVariant || !!id) data['locations'] = locationQuantity?.map(res => {
      let response = {
        location_id: res?.location?.value,
        amount: Number(res?.quantity)
      }
      if(!!res?.rack) response['rack'] = res?.rack
      return response
    })
    if(costForAll) data['cost'] = parseFloat(product.cost)
    if(!!product.offerPrice) data['offerPrice'] = product.offerPrice
    if(!id) data['all_image']= imagesForAll
    if(!id) data['all_price']= productForAll
    if(!id) data['all_cost']= costForAll
    if(!!product?.images?.filter((res) => !!res?.src)?.length && !!pdfFile?.src) data["images"] = [...data?.images, pdfFile?.src]
    // if(!!id && !productForAll) data['price']= Number(customVariant[0]?.price)
    if(!!id && !costForAll) data['cost']= Number(customVariant[0]?.cost)
    if(!id && hasVariant) data['variant_data'] = customVariant?.map(({ price_three, price_two, images, price_one, cost, locationQuantity }) => ({ price_three, price_two, images, price_one, cost, locationQuantity  }))?.map((res) => {
      let info = {
        cost: Number(res?.cost) || 0,
        images: res?.images?.filter(img=> !!img.src)?.map(img=> img.src)
      };
      if(!!pdfFile?.src) info['images'] = [...info?.images, pdfFile?.src]
      info['locations']= res?.locationQuantity?.map(loc => {
        let locData = { 
          location_id: loc?.location?.value,
          amount: loc?.quantity,
        }
        if(!!loc?.rack) locData['rack'] = loc?.rack
        return locData
      })
      if(!productForAll){
        info['price_one'] = Number(res?.price_one)
        info['price_two'] = Number(res?.price_two)
        if(!!res?.price_three) info['price_three'] = Number(res?.price_three)
      }
      return info
    })
    if(!!id){
      data['price_one'] = parseFloat(customVariant[0]?.price_one)
      data['price_two'] = parseFloat(customVariant[0]?.price_two)
      if(!!customVariant[0]?.price_three) data['price_three'] = parseFloat(customVariant[0]?.price_three)
    }
    if(hasVariant){
      data['variant'] = !id ? customVariant.map(({ locationQuantity, images, price_one, price_two, price_three, cost, ...rest }) => ({ ...rest }))?.map(res=>{
        return Object.values(res).filter(item => !!item)?.map(data=>{
          return {
            variant_value_id: data?.id,
            variant_id: data?.variant_id,
          }
        })
      }) : customVariant.map(({ locationQuantity, images, price_one, price_two, price_three, cost, ...rest }) => ({ ...rest }))?.map(res=>{
        return Object.values(res).filter(item => !!item)?.map(data=>{
          return {
            variant_value_id: data?.id,
            variant_id: data?.variant_id,
          }
        })
      })[0]
    }
    // if(!!id && hasVariant) data['amount'] = customVariant[0]?.quantity

    if (!!id) {
      productsService.update(id, data)?.then((res) => {
        if (res.data?.status === 200) {
          toast.success("Product Updated Successfully");
          navigate(`/products`)
        }
        setLoadning(false);
      }).catch(e=> toast.error(e.response?.data?.message?.replaceAll('_', ' ')));
    } else {
      productsService.create(data)?.then((res) => {
        if (res.data?.status === 201) {
          toast.success("Product Added Successfully");
        }
        setLoadning(false);
      }).catch(e=> toast.error(e.response?.data?.message?.replaceAll('_', ' ')));
    }
  };

  const deleteImg = (index) => {
    let updateFiles = files?.map((file, updateIndex) => {
      if (updateIndex === index) {
        return {};
      } else {
        return file;
      }
    });
    setFiles([...updateFiles]);
    let update = product?.images.map((item, ind) => {
      if (ind === index) {
        return {
          ...item,
          src: "",
        };
      } else {
        return { ...item };
      }
    });
    setProduct({ ...product, images: update });
  };

  if (Auth.showLoading) {
    return (
      <Card className="p-4" style={{ minHeight: "30rem" }}>
        <Loader />
      </Card>
    );
  }
  return (
    <Card className="p-4">
      <AvForm onValidSubmit={submit} className="add-product">
        <Row>
          <Col md={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.english_title}*`}
              type="text"
              placeholder={Translate[lang]?.english}
              bsSize="lg"
              name="name_en"
              disabled={view}
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              value={product.name_en}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.arabic_title}*`}
              type="text"
              placeholder={Translate[lang]?.arabic}
              value={product.name_ar}
              name="name_ar"
              disabled={view}
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                }
              }}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} className="mb-3">
            <label className="text-label">
              {Translate[lang]?.english_description}*
            </label>
            <textarea
              name="description_en"
              disabled={view}
              style={{
                minHeight: "80px",
                maxHeight: "150px",
                height: "150px",
              }}
              className="form-control"
              required
              placeholder={Translate[lang]?.english}
              value={product.description_en}
              onChange={(e) => {
                handlerText(e);
              }}
              rows="6"
            ></textarea>
          </Col>
          <Col md={6} className="mb-3">
            <label className="text-label">
              {Translate[lang]?.arabic_description}*
            </label>
            <textarea
              name="description_ar"
              disabled={view}
              style={{
                minHeight: "80px",
                maxHeight: "150px",
                height: "150px",
              }}
              className="form-control"
              placeholder={Translate[lang]?.arabic}
              value={product.description_ar}
              onChange={(e) => {
                handlerText(e);
              }}
              rows="6"
            ></textarea>
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <label className="text-label">{Translate[lang]?.category}*</label>
            <Select
              value={product.category}
              name="category"
              placeholder={Translate[lang]?.select}
              options={!view ? categoriesOptions : []}
              onChange={(e) =>
                setProduct({
                  ...product,
                  category: e,
                  dynamic_variant: [],
                  variant: [],
                })
              }
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <label className="text-label">{Translate[lang]?.brand}*</label>
            <Select
              value={product.brand}
              name="brand"
              placeholder={Translate[lang]?.select}
              options={!view ? brandsOptions : []}
              onChange={(e) =>
                setProduct({
                  ...product,
                  brand: e,
                })
              }
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.code}*`}
              type="text"
              placeholder={Translate[lang]?.code}
              bsSize="lg"
              name="code"
              disabled={view}
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                }
              }}
              value={product.code}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <label className="text-label">{Translate[lang]?.manufacturer}*</label>
            <Select
              value={product.manufacturer}
              name="manufacturer"
              placeholder={Translate[lang]?.select}
              options={!view ? manufacturerOptions : []}
              onChange={(e) =>
                setProduct({
                  ...product,
                  manufacturer: e,
                })
              }
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.country_of_origin}*`}
              type="text"
              placeholder={Translate[lang]?.country_of_origin}
              bsSize="lg"
              name="country_of_origin"
              disabled={view}
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                }
              }}
              value={product.country_of_origin}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          {(!id && hasVariant)&& <Col md={6} sm={6} className="mb-4">
            <label className="d-block text-label mb-0 mt-2" style={{ marginLeft: "8px" }}>
              <input
                type='checkbox' 
                name='productForAll' 
                className={`${lang === 'ar' ? 'ml-2' : 'mr-2'}`} 
                onClick={(e) => setProductForAll(e.target.checked)} />
              {Translate[lang]?.price_for_all_products}
            </label>
            <label className="d-block text-label mb-0 mt-2" style={{ marginLeft: "8px" }}>
              <input
                type='checkbox' 
                name='costForAll' 
                className={`${lang === 'ar' ? 'ml-2' : 'mr-2'}`} 
                onClick={(e) => setCostForAll(e.target.checked)} />
              {Translate[lang]?.cost_for_all_products}

            </label>
          </Col>}
          {productForAll &&<> 
            <Col md={6} sm={6} className="mb-3">
              <AvField
                label={`${Translate[lang]?.selling_price_kwd}*`}
                type="number"
                placeholder={Translate[lang]?.selling_price_kwd}
                bsSize="lg"
                name="price_one"
                min="0.0000000000001"
                disabled={view}
                validate={{
                  required: {
                    value: true,
                    errorMessage: Translate[lang].field_required,
                  },
                }}
                value={product.price_one}
                onChange={(e) => handlerText(e)}
              />
            </Col>
            <Col md={6} sm={6} className="mb-3">
              <AvField
                label={`${Translate[lang]?.selling_price_two}*`}
                type="number"
                placeholder={Translate[lang]?.selling_price_two}
                bsSize="lg"
                name="price_two"
                min="0.0000000000001"
                disabled={view}
                validate={{
                  required: {
                    value: true,
                    errorMessage: Translate[lang].field_required,
                  },
                }}
                value={product.price_two}
                onChange={(e) => handlerText(e)}
              />
            </Col>
            <Col md={6} sm={6} className="mb-3">
              <AvField
                label={`${Translate[lang]?.selling_price_three}`}
                type="number"
                placeholder={Translate[lang]?.selling_price_three}
                bsSize="lg"
                name="price_three"
                min="0.0000000000001"
                disabled={view}
                // validate={{
                //   required: {
                //     value: true,
                //     errorMessage: Translate[lang].field_required,
                //   },
                // }}
                value={product.price_three}
                onChange={(e) => handlerText(e)}
              />
            </Col>
          </>}
          {costForAll && <Col md={6} sm={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.cost_price_kwd}*`}
              type="number"
              placeholder={Translate[lang]?.cost_price_kwd}
              bsSize="lg"
              disabled={view}
              name="cost"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              min="0.0000000000001"
              value={product.cost}
              onChange={(e) => handlerText(e)}
            />
          </Col>}
          <Col md={6} sm={6} className="mb-3">
            <label className="text-label">{Translate[lang]?.supplier}*</label>
            <Select
              value={product.supplier}
              name="supplier"
              placeholder={Translate[lang]?.select}
              options={!view ? supplierOptions : []}
              onChange={(e) =>
                setProduct({ ...product, supplier: e})
              }
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.unite}*`}
              type="text"
              placeholder={Translate[lang]?.unite}
              bsSize="lg"
              disabled={view}
              name="unite"
              value={product.unite}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <AvField
              label={Translate[lang]?.backing}
              type="text"
              placeholder={Translate[lang]?.backing}
              bsSize="lg"
              disabled={view}
              name="backing"
              value={product.backing}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <AvField
              label={Translate[lang]?.master_backing}
              type="text"
              placeholder={Translate[lang]?.master_backing}
              bsSize="lg"
              disabled={view}
              name="master_backing"
              value={product.master_backing}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} sm={6} className="mb-3">
            <AvField
              label={Translate[lang]?.offer_price}
              type="number"
              placeholder={Translate[lang]?.offer_price}
              min="0.0000000000001"
              bsSize="lg"
              validate={{
                required: {
                  value: product?.offer,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              disabled={view}
              name="offerPrice"
              value={product.offerPrice}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={2} sm={3} className="mb-3">
            <label className="text-label">{Translate[lang]?.best_seller}</label>
            <Form.Check
              type="switch"
              id={`bestSeller`}
              disabled={view}
              checked={product.bestSeller}
              onChange={(e) =>
                setProduct({ ...product, bestSeller: e.target.checked })
              }
            />
          </Col>
          <Col md={2} sm={3} className="mb-3">
            <label className="text-label">{Translate[lang]?.new_in}</label>
            <Form.Check
              type="switch"
              id={`newIn`}
              disabled={view}
              checked={product.newIn}
              onChange={(e) =>
                setProduct({ ...product, newIn: e.target.checked })
              }
            />
          </Col>
          <Col md={2} sm={3} className="mb-3">
            <label className="text-label">{Translate[lang]?.offer}</label>
            <Form.Check
              type="switch"
              id={`offer`}
              disabled={view}
              checked={product.offer}
              onChange={(e) =>
                setProduct({ ...product, offer: e.target.checked })
              }
            />
          </Col>
        </Row>

        {(!hasVariant || !!id) && <Row 
          className="mb-3 px-2"
          style={{ 
            boxShadow: 'rgb(222 222 222 / 89%) 0px 0px 5px', 
            padding: '24px 0 12px 0' 
          }}
        >
          {locationQuantity?.map((locQunt, index) => {
            return <React.Fragment key={index}>
              <Col md={3}>
                  <label className="text-label">
                    {Translate[lang].location}*
                  </label>
                  <Select
                    placeholder={Translate[lang]?.select}
                    options={!view ? locationOptions : []}
                    value={locQunt?.location}
                    onChange={(e) => {
                      let update = locationQuantity?.map((res, ind) => {
                        if (ind === index) {
                          return {
                            ...res,
                            location: e
                          };
                        } else{
                          return res
                        }
                      });
                      setLocationQuantity([...update]);
                    }}
                  />
              </Col>
              <Col md={3}>
                <AvField
                  label={`${Translate[lang]?.quantity}*`}
                  type="number"
                  placeholder={Translate[lang]?.quantity}
                  min="0"
                  bsSize="lg"
                  disabled={view}
                  name={`quantity${index}`}
                  value={locQunt?.quantity}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: Translate[lang].field_required,
                    },
                  }}
                  onChange={(e) => {
                    let update = locationQuantity?.map((res, ind) => {
                      if (ind === index) {
                        return {
                          ...res,
                          quantity: e.target.value,
                        };
                      } else{
                        return res
                      }
                    });
                    setLocationQuantity([...update]);
                  }}
                />
              </Col>
              <Col md={3}>
                <AvField
                  label={Translate[lang]?.rack}
                  type="text"
                  placeholder={Translate[lang]?.rack}
                  bsSize="lg"
                  disabled={view}
                  name={`rack${index}`}
                  value={locQunt?.rack}
                  // validate={{
                  //   required: {
                  //     value: true,
                  //     errorMessage: Translate[lang].field_required,
                  //   },
                  // }}
                  onChange={(e) => {
                    let update = locationQuantity?.map((res, ind) => {
                      if (ind === index) {
                        return {
                          ...res,
                          rack: e.target.value,
                        };
                      } else{
                        return res
                      }
                    });
                    setLocationQuantity([...update]);
                  }}
                />
              </Col>
              <Col md={3}>
                {index > 0 && <button
                  type="button" 
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'var(--danger)',
                    fontSize: '26px',
                    position: 'absolute',
                    top: '26px',
                    left: lang === 'ar' ? '12px' : 'auto',
                    right: lang === 'en' ? '12px' : 'auto',
                  }}
                  onClick={() => {
                    let update = locationQuantity?.filter((_, ind)=> ind !== index)
                    setLocationQuantity(update)
                  }}
                >
                  <i className="la la-times-circle"></i>
                </button>}
              </Col>
            </React.Fragment>
          })}

          {!id && <Col md={12} className="mt-3">
            <button
              className="btn btn-outline-secondary mb-4 "
              type="button"
              onClick={() =>
                setLocationQuantity([
                  ...locationQuantity, { quantity: "", rack:"", location: "" }
                ])
              }
            >
              {Translate[lang].add_new_value}
            </button>
          </Col>}
        </Row>}
        
        {variant?.length > 0 && 
          customVariant?.map((cVariant, index)=>{
            return <Row className="mb-3 position-relative" key={index} style={{ boxShadow: 'rgb(222 222 222 / 89%) 0px 0px 5px', padding: '24px 0 12px 0' }}> 
            {variant.map(res => {
              if(res.name_en === "color"){
                return <>
                <Col md={3} className='mb-3'>
                  <label className="text-label">
                    {lang === "en" ? "Color" : "اللون"}*
                  </label>
                  <Select
                    placeholder={Translate[lang]?.select}
                    options={!view ? res.variant_values?.map((res1) => {
                        return {
                          ...res1,
                          label: res1.value_en,
                          value: res1.value_en,
                        };
                    }) : []}
                    styles={{
                      option: (provided, state) => {
                        return {
                          ...provided,
                          "&": {
                            background: state.data?.value,
                            border: `1px solid ${state.data?.value}`,
                          },
                          "&:hover": {
                            border: "1px solid #fff",
                          },
                        };
                      },
                    }}
                    value={cVariant?.color}
                    onChange={(e) => {
                      let update = customVariant?.map((res1, ind) => {
                        if (ind === index) {
                          return {
                            ...res1,
                            color: {...e,variant_id: res.id},
                          };
                        } else {
                          return res1
                        }
                      });
                      setCustomVariant([...update]);
                    }}
                  />
                </Col>
                <Col md={1} className="d-flex align-items-center">
                  <span
                    className={`d-inline-block`}
                    style={{
                      background: cVariant?.color?.value,
                      width: "30px",
                      height: "30px",
                      border: "1px solid",
                      textAlign: "center",
                      marginTop: "16px",
                    }}
                  ></span>
                </Col>
                </>
              } else {
                return <Col md={4} className='mb-3'>
                  <label className="text-label">
                    {lang === "en" ? res.name_en : res.name_ar}*
                  </label>
                  <Select
                    placeholder={Translate[lang]?.select}
                    options={!view ? res.variant_values?.map((res1) => {
                        return {
                          ...res1,
                          label: lang === "en" ? res1.value_en : res1.value_ar,
                          value: res1.value_en,
                        };
                    }) : []}
                    value={cVariant[res?.name_en]}
                    onChange={(e) => {
                      let update = customVariant?.map((res1, ind) => {
                        if (ind === index) {
                          return {
                            ...res1,
                            [res.name_en]: {...e,variant_id: res.id},
                          };
                        } else{
                          return res1
                        }
                      });
                      setCustomVariant([...update]);
                    }}
                  />
                </Col>
              }
            })}
          {!productForAll && <>
            <Col md={4}>
                <AvField
                  label={`${Translate[lang]?.selling_price_kwd}*`}
                  type="number"
                  placeholder={Translate[lang]?.selling_price_kwd}
                  min="0"
                  bsSize="lg"
                  disabled={view}
                  name={`price_one${index}`}
                  value={cVariant?.price_one}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: Translate[lang].field_required,
                    },
                  }}
                  onChange={(e) => {
                    let update = customVariant?.map((res, ind) => {
                      if (ind === index) {
                        return {
                          ...res,
                          price_one: e.target.value,
                        };
                      } else{
                        return res
                      }
                    });
                    setCustomVariant([...update]);
                  }}
                />
            </Col>
            <Col md={4}>
                <AvField
                  label={`${Translate[lang]?.selling_price_two}*`}
                  type="number"
                  placeholder={Translate[lang]?.selling_price_two}
                  min="0"
                  bsSize="lg"
                  disabled={view}
                  name={`price_two${index}`}
                  value={cVariant?.price_two}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: Translate[lang].field_required,
                    },
                  }}
                  onChange={(e) => {
                    let update = customVariant?.map((res, ind) => {
                      if (ind === index) {
                        return {
                          ...res,
                          price_two: e.target.value,
                        };
                      } else{
                        return res
                      }
                    });
                    setCustomVariant([...update]);
                  }}
                />
            </Col>
            <Col md={4}>
                <AvField
                  label={Translate[lang]?.selling_price_three}
                  type="number"
                  placeholder={Translate[lang]?.selling_price_three}
                  min="0"
                  bsSize="lg"
                  disabled={view}
                  name={`price_three${index}`}
                  value={cVariant?.price_three}
                  onChange={(e) => {
                    let update = customVariant?.map((res, ind) => {
                      if (ind === index) {
                        return {
                          ...res,
                          price_three: e.target.value,
                        };
                      } else{
                        return res
                      }
                    });
                    setCustomVariant([...update]);
                  }}
                />
            </Col>
          </>}
          {!costForAll && <Col md={4}>
                <AvField
                  label={`${Translate[lang]?.cost}*`}
                  type="number"
                  placeholder={Translate[lang]?.cost}
                  min="0"
                  bsSize="lg"
                  disabled={view}
                  name={`cost${index}`}
                  value={cVariant?.cost}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: Translate[lang].field_required,
                    },
                  }}
                  onChange={(e) => {
                    let update = customVariant?.map((res, ind) => {
                      if (ind === index) {
                        return {
                          ...res,
                          cost: e.target.value,
                        };
                      } else{
                        return res
                      }
                    });
                    setCustomVariant([...update]);
                  }}
                />
          </Col>}
          {hasVariant && <Col md={12} className="mt-3">
            <Row>
            {cVariant?.locationQuantity?.map((locQunt, ind) => {
              return <React.Fragment key={index}>
                <Col md={3}>
                    <label className="text-label">
                      {Translate[lang].location}*
                    </label>
                    <Select
                      placeholder={Translate[lang]?.select}
                      options={!view ? locationOptions : []}
                      value={locQunt?.location}
                      onChange={(e) => {
                        let update = customVariant?.map((res, inn) => {
                          if(index === inn) {
                            return {
                              ...res,
                              locationQuantity: res?.locationQuantity?.map((resp, inde) =>{
                                if(ind === inde){
                                  return {
                                    ...resp,
                                    location: e
                                  }
                                } else {
                                  return resp
                                }
                              })
                            };
                          } else{
                            return res
                          }
                        });
                        setCustomVariant([...update]);
                      }}
                    />
                </Col>
                <Col md={3}>
                  <AvField
                    label={`${Translate[lang]?.quantity}*`}
                    type="number"
                    placeholder={Translate[lang]?.quantity}
                    min="0"
                    bsSize="lg"
                    disabled={view}
                    name={`quantity${ind}${index}`}
                    value={locQunt?.quantity}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: Translate[lang].field_required,
                      },
                    }}
                    onChange={(e) => {
                      let update = customVariant?.map((res, inn) => {
                        if(index === inn) {
                          return {
                            ...res,
                            locationQuantity: res?.locationQuantity?.map((resp, inde) =>{
                              if(ind === inde){
                                return {
                                  ...resp,
                                  quantity: e?.target?.value
                                }
                              } else {
                                return resp
                              }
                            })
                          };
                        } else{
                          return res
                        }
                      });
                      setCustomVariant([...update]);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <AvField
                    label={Translate[lang]?.rack}
                    type="text"
                    placeholder={Translate[lang]?.rack}
                    bsSize="lg"
                    disabled={view}
                    name={`racky${ind}${index}`}
                    value={locQunt?.rack}
                    // validate={{
                    //   required: {
                    //     value: true,
                    //     errorMessage: Translate[lang].field_required,
                    //   },
                    // }}
                    onChange={(e) => {
                      let update = customVariant?.map((res, inn) => {
                        if(index === inn) {
                          return {
                            ...res,
                            locationQuantity: res?.locationQuantity?.map((resp, inde) =>{
                              if(ind === inde){
                                return {
                                  ...resp,
                                  rack: e?.target?.value
                                }
                              } else {
                                return resp
                              }
                            })
                          };
                        } else{
                          return res
                        }
                      });
                      setCustomVariant([...update]);
                    }}
                  />
                </Col>
                <Col md={3}>
                  {ind > 0 && <button
                    type="button" 
                    style={{
                      background: 'none',
                      border: 'none',
                      color: 'var(--danger)',
                      fontSize: '26px',
                      position: 'absolute',
                      top: '26px',
                      left: lang === 'ar' ? '12px' : 'auto',
                      right: lang === 'en' ? '12px' : 'auto',
                    }}
                    onClick={() => {
                      let update = customVariant?.map((res, inn) => {
                        if(index === inn) {
                          return {
                            ...res,
                            locationQuantity: [...res?.locationQuantity?.filter((_, indd)=> indd !== ind)]
                          };
                        } else{
                          return res
                        }
                      });
                      setCustomVariant([...update]);
                    }}
                  >
                    <i className="la la-times-circle"></i>
                  </button>}
                </Col>
              </React.Fragment>
            })}

            {!id && <Col md={12} className="mt-3">
              <button
                className="btn btn-outline-secondary mb-4 "
                type="button"
                onClick={() =>{
                  let update = customVariant?.map((res, inn) => {
                    if(index === inn) {
                      return {
                        ...res,
                        locationQuantity: [...res?.locationQuantity, { quantity: "", rack:"", location: "" }]
                      };
                    } else{
                      return res
                    }
                  });
                  setCustomVariant([...update]);
                }}
              >
                {Translate[lang].add_new_value}
              </button>
            </Col>}
          </Row>
          </Col>}
          <Col md={12}>
          {(!id && !imagesForAll) && <>
            <label className="text-label mb-0 mt-4" style={{ marginLeft: "8px" }}>
              {Translate[lang]?.images}
            </label>
            <Row>
              {!id && cVariant?.images?.map((data, i) => {
              return (
                <Col md={2} sm={3} className="mb-3" key={i}>
                  <div className="image-placeholder">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        disabled={view}
                        onChange={(e) => variantFileHandler(e, i, index)}
                        id={`imageUpload${index}${i}`}
                      />
                      <label htmlFor={`imageUpload${index}${i}`} name=""></label>
                    </div>
                    <button
                      className="delete-img"
                      type="button"
                      disabled={view}
                      onClick={() => {
                        let update = customVariant.map((item, ind) => {
                          if (ind === index) {
                            return {
                              ...item,
                              images: item?.images?.map((img, imgInd)=>{
                              if(imgInd === i){
                                return { src: "" }
                              } else {
                                return img
                              }
                            })}
                          } else {
                            return { ...item };
                          }
                        });
                        setCustomVariant(update);
                      }}
                    >
                      <i className="la la-trash"></i>
                    </button>
                    <div className="avatar-preview4" style={{height: '9rem'}}>
                      {!!data?.src ? (
                        <div id={`image${index}${i}`}>
                          <img
                            id={`saveImage${index}${i}`}
                            src={data?.src}
                            alt="icon"
                            className="w-100 h-100"
                          />
                        </div>
                      ) : (
                        <div id={`image${index}${index}`}>
                          {files[index]?.name && (
                            <img
                              id={`saveImage${index}${index}`}
                              src={URL.createObjectURL(files[index])}
                              alt="icon"
                            />
                          )}
                          {!files[index]?.name && (
                            <img
                              id={`saveImage${index}${index}`}
                              src={uploadImg}
                              alt="icon"
                              style={{
                                width: "60px",
                                height: "60px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
            </Row>
          </>}
          </Col>
          {index > 0 && <button type="button" style={{
              background: 'none', border: 'none', color: 'var(--danger)', fontSize: '24px',
              position: 'absolute', top: '4px',
              left: lang==='ar' ? '12px' : 'auto',
              right: lang==='en' ? '12px' : 'auto',
              
            }}
            onClick={()=>{
              let update = customVariant.filter((_, ind)=> ind !== index)
              setCustomVariant(update)
            }}
          >
            <i className="la la-times-circle"></i>
          </button>}
        </Row>
          })
        }

        {/* Add New Value Button */}
        {(!id && variant?.length > 0) && (
          <button
            className="btn btn-outline-secondary mb-4 "
            type="button"
            onClick={() =>{
              let info = {
                ...staticVariantValue,
                locationQuantity: [{
                  location: '',
                  quantity: '',
                  rack: '',
                }],
                images: [{}, {}, {}, {}, {}]
              }
              if(!productForAll) {
                info['price_one'] = ''
                info['price_two'] = ''
                info['price_three'] = ''
              }
              setCustomVariant([
                ...customVariant,
                info,
              ])
            }}
          >
            {Translate[lang].add_new_value}
          </button>
        )}

        <Row className='mt-5'>
          <Col md={12} sm={12} className="mb-1">
            <label className="text-label mb-0">
              Pdf
            </label>
          </Col>
          <Col md={3} sm={6} className="mb-3">
            <div className="image-placeholder">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      disabled={view}
                      onChange={(e) => pdfFileHandler(e)}
                      id={`imageUploadPdf`}
                      accept=".doc,.docx,.pdf"
                    />
                    <label htmlFor={`imageUploadPdf`} name=""></label>
                  </div>
                  <div className="avatar-preview">
                    <div id={`imagePreviewPdf`}>
                      <img
                            id={`saveImageFilePdf`}
                            src={uploadImg}
                            alt="icon"
                            style={{
                              width: "60px",
                              height: "60px",
                            }}
                          />
                    </div>
                  </div>
            </div>
          </Col>
          {!!pdfFile.src && <Col md={3} sm={6} className="mb-3">
                <div className="position-absolute">
                  <button
                    className="delete-img"
                    type="button"
                    disabled={view}
                    onClick={() => setPdfFile({src: '', loading: false})}
                  >
                    <i className="la la-trash" style={{top: '8px', right: '30px'}}></i>
                  </button>
                  <div className="avatar-preview">
                    <div id={`imagePreviewPdf`}>
                      <a href={pdfFile?.src} target="_blank" rel="noreferrer">
                        <i className="la la-file-pdf" style={{ fontSize: '15rem' }}></i>
                      </a>
                    </div>
                  </div>
                </div>
          </Col>}
        </Row>
       {(!id && hasVariant) ? <label className="d-block text-label mb-0 mt-4" style={{ marginLeft: "8px" }}>
          <input
            type='checkbox' 
            name='images' 
            className={`${lang === 'ar' ? 'ml-2' : 'mr-2'}`} 
            onClick={(e) => setImagesForAll(e.target.checked)} />
          {Translate[lang]?.images_for_all_products}

        </label> : <label className="text-label mb-0 mt-4" style={{ marginLeft: "8px" }}>
          {Translate[lang]?.images}
        </label>}
        <Row>
          {product?.images?.map((data, index) => {
            return (
              <Col md={3} sm={6} className="mb-3" key={index}>
                <div className="image-placeholder">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      disabled={view}
                      onChange={(e) => fileHandler(e, index)}
                      id={`imageUpload${index}`}
                      accept="image/*"
                    />
                    <label htmlFor={`imageUpload${index}`} name=""></label>
                  </div>
                  <button
                    className="delete-img"
                    type="button"
                    disabled={view}
                    onClick={() => deleteImg(index)}
                  >
                    <i className="la la-trash"></i>
                  </button>
                  <div className="avatar-preview">
                    {!!data?.src ? (
                      <div id={`imagePreview${index}`}>
                        <img
                          id={`saveImageFile${index}`}
                          src={data?.src}
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <div id={`imagePreview${index}`}>
                        {files[index]?.name && (
                          <img
                            id={`saveImageFile${index}`}
                            src={URL.createObjectURL(files[index])}
                            alt="icon"
                          />
                        )}
                        {!files[index]?.name && (
                          <img
                            id={`saveImageFile${index}`}
                            src={uploadImg}
                            alt="icon"
                            style={{
                              width: "60px",
                              height: "60px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>

        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigate("/products")}
          >
            {Translate[lang]?.cancel}
          </Button>
          {!view && <Button variant="primary" loading={loading} type="submit">
            {Translate[lang]?.submit}
          </Button>}
        </div>
      </AvForm>
    </Card>
  );
};

export default AddProducts;