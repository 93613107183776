import { API_BASE_URL_ENV } from "../jsx/common/common";
import BaseService from "./BaseService";
import http from './HttpService';

const apiEndpoint = API_BASE_URL_ENV() + "/quotation";
const apiSendResponseEndpoint = API_BASE_URL_ENV() + "/quotation/admin/send";

export default class QuotationService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getList(params) {
        if(params){
          return http.get(`${apiEndpoint}/admin`, {params});
        } else {
          return http.get(`${apiEndpoint}/admin`);
        }
    }
  
    sendRespone(params) {
      return http.put(apiSendResponseEndpoint, params );
    }
}
