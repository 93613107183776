import BaseService from "./BaseService";
import http from "./HttpService";
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/client";
const apiRemoveEndpoint = API_BASE_URL_ENV() + "/client/client";

export default class ClientsService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  remove=(id)=>{
    return http.delete(`${apiRemoveEndpoint}/${id}`)
  }
}
